import './Saloon_Dashboard.css';
import '../../App.css';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import React from 'react';
import rightdoctor from '../../media/backwomen.jpg';
import DoctorSidebar from './SaloonSidebar'
import './Sidebar.css'
import Doctorsidebarresponsive from './Saloonsidebarresponsive';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import GoToTop from '../GoToTop';


import { Button, notification } from "antd";

const openNotification = () => {
    notification.open({
        style: { padding: '10px', borderRadius: '10px', width: '280px' },
        duration: 0,
        placement: "bottomLeft",
        message:
            <div>
                <div className='doctor_dashboard_notification_main_div'>
                    <div>
                        <img className='doctor_dashboard_notification_patient_image' src={rightdoctor}></img>
                    </div>
                    <div>
                        <div className='doctor_dashboard_notification_patient_name'>Peril Patel</div>
                        <div className='doctor_dashboard_notification_booking_time'>09:00 AM - 09:30 AM</div>
                        <div className='doctor_dashboard_notification_date'>24 APR 2023</div>
                    </div>


                </div>
                <div>
                    <button className='btn doctor_dashboard_tabel_accept_btn_mobile mt-3' >
                        {/* <i class="fa-solid fa-check"></i> */}
                        Accept
                    </button>
                    <button className='btn doctor_dashboard_tabel_cancel_btn_mobile mt-3'>
                        {/* <i class="fa-solid fa-xmark"></i> */}
                        Reject
                    </button>
                </div>
            </div>,


    });

};



function Doctor_Dashboard() {
    let navigate = useNavigate();


    function Pending_Payment(Paymentindex) {
        console.log(AppointmentData[Paymentindex])
        var Url = window.API_PREFIX + 'admin/Paybydoctor'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: AppointmentData[Paymentindex]['BookingId'],
                    PatientId: AppointmentData[Paymentindex]['PatientId']


                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                var tmp = AppointmentData
                tmp[Paymentindex]['BookingStatus'] = 2
                setAppointmentData([...tmp])
            }
        })

    }


    function AcceptRequest(Requestindex) {
        var Url = window.API_PREFIX + 'booking/approve_booking'
        console.log(AppointmentData[Requestindex])
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: AppointmentData[Requestindex]['BookingId']


                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                var tmp = AppointmentData
                tmp[Requestindex]['BookingStatus'] = 1
                setAppointmentData([...tmp])
            }
        })


    }


    function RejectRequest(Requestindex) {


        var Url = window.API_PREFIX + 'booking/reject_booking'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: AppointmentData[Requestindex]['BookingId'],




                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                var tmp = AppointmentData
                tmp[Requestindex]['BookingStatus'] = -1
                setAppointmentData([...tmp])
            }
        })

    }

    // function AcceptRequest(Requestindex) {
    //     var tmp = AppointmentData
    //     tmp[Requestindex]['BookingStatus'] = 1
    //     setAppointmentData([...tmp])
    // }

    // function RejectRequest(Requestindex) {
    //     var tmp = AppointmentData
    //     tmp[Requestindex]['BookingStatus'] = -1
    //     setAppointmentData([...tmp])
    // }



    const columns = [
        {
            title: 'Customer Name',

            dataIndex: 'PatientName',
            key: 'name',
            //   render: (text) => <a>{text}</a>,
            render: (text, record) => <>
                <div className='doctor_dashboard_table_image_name_division'>
                    <div className='doctor_dashboard_table_image_division'>
                        <img className='doctor_dashboard_table_image' src={record['ProfilePic']}
                            height="50px" width="50px" /></div>
                    <div className='doctor_dashboard_table_name_id_division'>
                        <div className='doctor_dashboard_table_patient_name'>{text}</div>
                        <div className='doctor_dashboard_table_patient_id'>#pt{record['PatientId']}</div>
                    </div>
                </div>
            </>

        },
        {
            title: 'Appt Date',
            dataIndex: 'Date',
            key: 'date',
            render: (text, record) => <>
                <div className='doctor_dashboard_time_date_div'>
                    <div className='doctor_dashboard_table_appt_date'>{text}</div>
                    <div className='doctor_dashboard_table_appt_time'>{record['Time']}</div>
                </div>
            </>
        },
        {
            title: 'Purpose',
            dataIndex: 'Purpose',
            key: 'purpose',
            render: (text, record) => <>
                <div className='doctor_dashboard_table_appt_date'>{text}</div>

            </>
        },
        {
            title: 'Type',
            dataIndex: 'Patienthistory',
            key: 'type',
            render: (text, record) => <>
                <div className='doctor_dashboard_table_appt_date'>{text ? 'Old Customer' : 'New Customer'}</div>

            </>
        },
        {
            title: 'Paid Amount',
            dataIndex: 'Amount',
            key: 'paidamount',
            //         render: (text,record) => <>
            //         <div className='doctor_dashboard_table_appt_date'>{text}</div>

            // </>
        },
        {

            title: 'Option',
            key: 'tags',
            dataIndex: 'BookingStatus',
            render: (text, record, index) => (
                <>
                    {text === 0 ? <div className='doctor_dashboard_tabel_view_accept_cancel'>
                        <div><button className='btn doctor_dashboard_tabel_accept_btn'
                            onClick={() => {
                                AcceptRequest(index)
                            }}
                        >Accept</button></div>
                        <div><button className='btn doctor_dashboard_tabel_cancel_btn'

                            onClick={() => {
                                RejectRequest(index)
                            }}
                        >Reject</button></div>
                    </div> : text === 1 ?
                        <div className='doctor_dashboard_tabel_view_accept_cancel'>
                            <div><button className='btn doctor_dashboard_tabel_accept_btn' disabled>Accepted</button></div>
                        </div>
                        : text === 2 ?
                            <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                <div><button className='btn doctor_dashboard_tabel_accept_btn' disabled>Accepted</button></div>
                            </div>
                            :
                            <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                <div><button className='btn doctor_dashboard_tabel_cancel_btn' disabled>Rejected</button></div>
                            </div>
                    }
                </>
            ),
        },
        {

            title: 'Payment Status',
            key: 'tags',
            dataIndex: 'Ispaid',
            render: (text, record, index) => (
                <>
                    <div className='doctor_dashboard_tabel_view_accept_cancel'>
                        {record['BookingStatus'] === 1 ? <div><button className='btn doctor_dashboard_tabel_view_btn'
                            onClick={() => {
                                Pending_Payment(index)
                            }}
                        >
                            {/* <i class="fa-regular fa-eye me-1"></i> */}
                            Pending Payment</button></div>
                            :
                            record['BookingStatus'] === 2 ?
                                <div><button className='btn doctor_dashboard_tabel_accept_btn' disabled>
                                    {/* <i class="fa-regular fa-eye me-1"></i> */}
                                    Paid</button></div> :
                                record['BookingStatus'] === 0 ?
                                    <div><button className='btn doctor_dashboard_tabel_view_btn' disabled>
                                        {/* <i class="fa-regular fa-eye me-1"></i> */}
                                        Pending Request</button></div> :
                                    <div><button className='btn doctor_dashboard_tabel_cancel_btn' disabled>
                                        {/* <i class="fa-regular fa-eye me-1"></i> */}
                                        N/A</button></div>

                        }
                        {/* <div><button className='btn doctor_dashboard_tabel_cancel_btn'><i class="fa-regular fa-eye me-1"></i>Cancel</button></div> */}
                    </div>
                </>
            ),
        },
    ];

    const [AppointmentData, setAppointmentData] = useState([])
    const [AppointmentOption, setAppointmentOption] = useState([true, false, false])

    useEffect(() => {
        var Url = window.API_PREFIX + 'admin/upcoming_booking'
        fetch(Url, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            },
            // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setAppointmentData([...data["Booking"]])
            }
        })
    }, [])





    // responsive table data start



    const [ActiveUserTab, SetActiveUserTab] = useState([true, false, false])

    const appoinmentupcomingdata = [
        {
            id: 1,
            name: 'peril patel',
            id: '04',
            date: '25/04/2000',
            amount: '45000',
            time: '9:00-10:45 AM',
            purpose: 'Fever',
        }

    ]

    const appoinmenttodaydata = [
        {
            id: 1,
            name: 'ramesh patel',
            id: '02',
            date: '01/04/2023',
            amount: '120',
            time: '07:00-08:00 AM',
            purpose: 'accident',
        }

    ]

    const appoinmentpreviousdata = [
        {
            id: 1,
            name: 'suresh patel',
            id: '16',
            date: '05/05/2023',
            amount: '520',
            time: '11:00-12:00 AM',
            purpose: 'head pain',
        }
    ]


    // responsive table data end

    return (
        <>
            {/* breadcrumbs-start */}
            <div className='doctor_dashboard_breadcrumb_main_division' >
                <div className='doctor_dashboard_breadcrumb_division'>
                    <p className='doctor_dashboard_breadcrumb_title' onClick={() => { navigate('/') }}>home</p>
                    <p className='doctor_dashboard_breadcrumb_title1'>/</p>
                    <p className='doctor_dashboard_breadcrumb_title2'>dashboard</p>
                </div>
                <div>
                    <h1 className='doctor_dashboard_breadcrumb_title_main'>dashboard</h1>
                </div>
            </div>
            {/* breadcrumbs-end */}

            <div className="container-fuild">
                <div className='slidebar_and_component'>
                    <DoctorSidebar />
                    <Doctorsidebarresponsive />
                    {/* <div className='sidebar_main_division'>
                        <div className='sidebar_division'></div>

                   
                    </div> */}
                    <div className='component_division'>
                        <div className='doctor_dashboard_main_diviison'>
                            <p className='doctor_dashboard_patientappoinment_name'>Customer Appoinment</p>
                            <div className='doctor_dashboard_patient_appointment_table_main_division'>
                                <div className='doctor_dashboard_upcoming_today_btn_divsion'>
                                    <div className='doctor_dashboard_today_btn_divsion'>
                                        <button type="button" class={AppointmentOption[0] ? "btn doctor_dashboard_upcoming_btn_active" : 'btn doctor_dashboard_upcoming_btn'}
                                            onClick={() => {

                                                var Url = window.API_PREFIX + 'admin/upcoming_booking'
                                                fetch(Url, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] === "1") {
                                                        setAppointmentOption([true, false, false])

                                                        setAppointmentData([...data["Booking"]])
                                                    }
                                                })
                                            }}
                                        >Upcoming</button>
                                    </div>
                                    <div className='doctor_dashboard_today_btn_divsion'>
                                        <button type="button" class={AppointmentOption[1] ? "btn doctor_dashboard_upcoming_btn_active" : 'btn doctor_dashboard_upcoming_btn'}
                                            onClick={() => {
                                                var Url = window.API_PREFIX + 'admin/Today_booking'
                                                fetch(Url, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] === "1") {
                                                        setAppointmentOption([false, true, false])
                                                        setAppointmentData([...data["Booking"]])
                                                    }
                                                })

                                            }}
                                        >Today</button>
                                    </div>
                                    <div className='doctor_dashboard_today_btn_divsion'>
                                        <button type="button" class={AppointmentOption[2] ? "btn doctor_dashboard_upcoming_btn_active" : 'btn doctor_dashboard_upcoming_btn'}
                                            onClick={() => {


                                                var Url = window.API_PREFIX + 'admin/Previous_booking'
                                                fetch(Url, {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] === "1") {
                                                        setAppointmentOption([false, false, true])

                                                        setAppointmentData([...data["Booking"]])
                                                    }
                                                })
                                            }}>Previous</button>
                                    </div>
                                </div>
                                <div className='doctor_dashboard_patient_appointment_table_division'>
                                    <Table columns={columns} dataSource={AppointmentData} />
                                    <Button type="primary" onClick={openNotification}>
                                        Open the notification box
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className='doctor_dashboard_table'>


                        {ActiveUserTab[0] ?
                            <div className='doctor_dashboard_table_appoinment_table_main'>
                                {appoinmentupcomingdata.map((appoinmentupcoming) => (
                                    <div className='doctor_dashbord_mobile_appointments_main_division'>

                                        <div className='doctor_dashbord_mobile_appointments_pic_name_section'>
                                            <div>
                                                <img height="50px" width="50px"></img>
                                            </div>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_appoinment_booked_date'>{appoinmentupcoming.name}</div>
                                                <div className='doctor_dashboard_mobile_patient_number'>{appoinmentupcoming.id}</div>
                                            </div>
                                        </div>
                                        <div className='doctor_dashboard_mobile_table_appoinment_table_time_division'>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>APPT. DATE</div>
                                                <div className='doctor_dashboard_mobile_table_appoinment_table_date'>{appoinmentupcoming.date}</div>
                                            </div>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>AMOUNT</div>
                                                <div className='doctor_dashboard_mobile_table_appoinment_amount'>₹{appoinmentupcoming.amount}</div>
                                            </div>
                                        </div>
                                        <div className='doctor_dashboard_mobile_table_appoinment_time_division'>
                                            <div className='doctor_dashboard_mobile_table_small_title'>APPOINMENT TIME</div>
                                            <div className='doctor_dashboard_mobile_table_appoinment_table_time'>{appoinmentupcoming.time}</div>
                                        </div>
                                        <div className='doctor_dashboard_mobile_table_appoinment_table_purpose_division'>
                                            <div className='doctor_dashboard_mobile_table_small_title'>Purpose</div>
                                            <div className='doctor_dashboard_mobile_table_purpose_value'>{appoinmentupcoming.purpose}</div>
                                        </div>

                                        <div className='doctor_dashboard_btn_div'>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>BOOKING STATUS</div>
                                                <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                                    <div>
                                                        <button className='btn doctor_dashboard_tabel_accept_btn_mobile'><i class="fa-solid fa-check"></i></button>
                                                    </div>
                                                    <div>
                                                        <button className='btn doctor_dashboard_tabel_cancel_btn_mobile'><i class="fa-solid fa-xmark"></i></button>
                                                    </div>
                                                </div>
                                                <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                                    <div>
                                                        {/* <button className='btn doctor_dashboard_tabel_accept_btn' disabled>Accepted</button> */}
                                                    </div>
                                                </div>
                                                <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                                    <div>
                                                        {/* <button className='btn doctor_dashboard_tabel_cancel_btn' disabled >Rejected</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>PAYMENT STATUS</div>
                                                <div>
                                                    <button className='btn doctor_dashboard_tabel_view_btn'>Pending Payment</button>
                                                </div>
                                                <div>
                                                    {/* <button className='btn doctor_dashboard_tabel_accept_btn' disabled>Paid</button> */}
                                                </div>
                                                <div>
                                                    {/* <button className='btn doctor_dashboard_tabel_view_btn' disabled> Pending Request</button> */}
                                                </div>
                                                <div>
                                                    {/* <button className='btn doctor_dashboard_tabel_cancel_btn' disabled>N/A</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                            </div> : null}


                        {ActiveUserTab[1] ?
                            <div className='doctor_dashboard_table_prescription_table_main'>
                                {appoinmenttodaydata.map((appoinmenttoday) => (

                                    <div className='doctor_dashbord_mobile_appointments_main_division'>

                                        <div className='doctor_dashbord_mobile_appointments_pic_name_section'>
                                            <div>
                                                <img height="50px" width="50px"></img>
                                            </div>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_appoinment_booked_date'>{appoinmenttoday.name}</div>
                                                <div className='doctor_dashboard_mobile_patient_number'>{appoinmenttoday.id}</div>
                                            </div>
                                        </div>
                                        <div className='doctor_dashboard_mobile_table_appoinment_table_time_division'>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>APPT. DATE</div>
                                                <div className='doctor_dashboard_mobile_table_appoinment_table_date'>{appoinmenttoday.date}</div>
                                            </div>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>AMOUNT</div>
                                                <div className='doctor_dashboard_mobile_table_appoinment_amount'>₹{appoinmenttoday.amount}</div>
                                            </div>
                                        </div>
                                        <div className='doctor_dashboard_mobile_table_appoinment_time_division'>
                                            <div className='doctor_dashboard_mobile_table_small_title'>APPOINMENT TIME</div>
                                            <div className='doctor_dashboard_mobile_table_appoinment_table_time'>{appoinmenttoday.time}</div>
                                        </div>
                                        <div className='doctor_dashboard_mobile_table_appoinment_table_purpose_division'>
                                            <div className='doctor_dashboard_mobile_table_small_title'>Purpose</div>
                                            <div className='doctor_dashboard_mobile_table_purpose_value'>{appoinmenttoday.purpose}</div>
                                        </div>

                                        <div className='doctor_dashboard_btn_div'>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>BOOKING STATUS</div>
                                                <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                                    <div>
                                                        <button className='btn doctor_dashboard_tabel_accept_btn_mobile'><i class="fa-solid fa-check"></i></button>
                                                    </div>
                                                    <div>
                                                        <button className='btn doctor_dashboard_tabel_cancel_btn_mobile'><i class="fa-solid fa-xmark"></i></button>
                                                    </div>
                                                </div>
                                                <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                                    <div>
                                                        {/* <button className='btn doctor_dashboard_tabel_accept_btn' disabled>Accepted</button> */}
                                                    </div>
                                                </div>
                                                <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                                    <div>
                                                        {/* <button className='btn doctor_dashboard_tabel_cancel_btn' disabled >Rejected</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>PAYMENT STATUS</div>
                                                <div>
                                                    <button className='btn doctor_dashboard_tabel_view_btn'>Pending Payment</button>
                                                </div>
                                                <div>
                                                    {/* <button className='btn doctor_dashboard_tabel_accept_btn' disabled>Paid</button> */}
                                                </div>
                                                <div>
                                                    {/* <button className='btn doctor_dashboard_tabel_view_btn' disabled> Pending Request</button> */}
                                                </div>
                                                <div>
                                                    {/* <button className='btn doctor_dashboard_tabel_cancel_btn' disabled>N/A</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> : null}

                        {ActiveUserTab[2] ?
                            <div className='doctor_dashboard_table_medicalrecords_table_main'>
                                {appoinmentpreviousdata.map((appoinmentprevious) => (
                                    <div className='doctor_dashbord_mobile_appointments_main_division'>

                                        <div className='doctor_dashbord_mobile_appointments_pic_name_section'>
                                            <div>
                                                <img height="50px" width="50px"></img>
                                            </div>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_appoinment_booked_date'>{appoinmentprevious.name}</div>
                                                <div className='doctor_dashboard_mobile_patient_number'>{appoinmentprevious.id}</div>
                                            </div>
                                        </div>
                                        <div className='doctor_dashboard_mobile_table_appoinment_table_time_division'>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>APPT. DATE</div>
                                                <div className='doctor_dashboard_mobile_table_appoinment_table_date'>{appoinmentprevious.date}</div>
                                            </div>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>AMOUNT</div>
                                                <div className='doctor_dashboard_mobile_table_appoinment_amount'>₹{appoinmentprevious.amount}</div>
                                            </div>
                                        </div>
                                        <div className='doctor_dashboard_mobile_table_appoinment_time_division'>
                                            <div className='doctor_dashboard_mobile_table_small_title'>APPOINMENT TIME</div>
                                            <div className='doctor_dashboard_mobile_table_appoinment_table_time'>{appoinmentprevious.time}</div>
                                        </div>
                                        <div className='doctor_dashboard_mobile_table_appoinment_table_purpose_division'>
                                            <div className='doctor_dashboard_mobile_table_small_title'>Purpose</div>
                                            <div className='doctor_dashboard_mobile_table_purpose_value'>{appoinmentprevious.purpose}</div>
                                        </div>

                                        <div className='doctor_dashboard_btn_div'>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>BOOKING STATUS</div>
                                                <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                                    <div>
                                                        <button className='btn doctor_dashboard_tabel_accept_btn_mobile'><i class="fa-solid fa-check"></i></button>
                                                    </div>
                                                    <div>
                                                        <button className='btn doctor_dashboard_tabel_cancel_btn_mobile'><i class="fa-solid fa-xmark"></i></button>
                                                    </div>
                                                </div>
                                                <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                                    <div>
                                                        {/* <button className='btn doctor_dashboard_tabel_accept_btn' disabled>Accepted</button> */}
                                                    </div>
                                                </div>
                                                <div className='doctor_dashboard_tabel_view_accept_cancel'>
                                                    <div>
                                                        {/* <button className='btn doctor_dashboard_tabel_cancel_btn' disabled >Rejected</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='doctor_dashboard_mobile_table_small_title'>PAYMENT STATUS</div>
                                                <div>
                                                    <button className='btn doctor_dashboard_tabel_view_btn'>Pending Payment</button>
                                                </div>
                                                <div>
                                                    {/* <button className='btn doctor_dashboard_tabel_accept_btn' disabled>Paid</button> */}
                                                </div>
                                                <div>
                                                    {/* <button className='btn doctor_dashboard_tabel_view_btn' disabled> Pending Request</button> */}
                                                </div>
                                                <div>
                                                    {/* <button className='btn doctor_dashboard_tabel_cancel_btn' disabled>N/A</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div> : null}
                    </div>
                </div>
                <div className='doctor_Dashboard_table_first_sction'>
                    <button type="button" className={ActiveUserTab[0] ? 'btn doctor_patient_dashboard_btn_active' : ' btn doctor_patient_dashboard_btn'} onClick={() => SetActiveUserTab([true, false, false])} >Upcoming</button>
                    <button type="button" className={ActiveUserTab[1] ? 'btn doctor_patient_dashboard_btn_active' : ' btn doctor_patient_dashboard_btn'} onClick={() => SetActiveUserTab([false, true, false])} >Today</button>
                    <button type="button" className={ActiveUserTab[2] ? 'btn doctor_patient_dashboard_btn_active' : ' btn doctor_patient_dashboard_btn'} onClick={() => SetActiveUserTab([false, false, true])}>Previous</button>
                </div>
                <GoToTop />
            </div>
        </>
    )
}
export default Doctor_Dashboard;