import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
import Footer from '../../src/Component/Footer/Footer';
import { useEffect } from 'react';
import { useStateValue } from './StateProviders';
import { useNavigate } from 'react-router-dom';
import DoccureLogin from './Customer/CustomerLogin'
import PatientRegister from './Customer/PatientRegister';
// import Homepage from './HomePage/Homepage';


export default function HomeLayout() {
  let navigate = useNavigate()
  const [{ IsLogin, IsRegister }, dispatch] = useStateValue();
  useEffect(() => {
 
    console.log(IsRegister)
    if (localStorage.getItem('DToken') !== null) {
      dispatch({ type: 'Login' });
    }
    else {
      dispatch({ type: 'Logout' });
    }


  }, [])
  return (<>
    <Header />

    {
    window.location.pathname === '/' ||
    window.location.pathname === '/ContactUs' ||
    window.location.pathname === '/aboutus'

    
    ?<Outlet/>:
    !IsLogin?
        <DoccureLogin/>:
        !IsRegister?
     <PatientRegister/>: 
     
      <Outlet />}

    <Footer />
  </>
  )
} 