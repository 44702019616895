import "./CustomerDashboard.css";
import "antd/dist/antd.css";
import { Space, Table, Tag } from "antd";
import "../Customer/Sidebar.css";
import React from "react";
import { useEffect, useState } from "react";
import PatientSidebar from "./CustomerSidebar";
import Popup from "reactjs-popup";
import Patientsidebarresponsive from "./Customersidebarresponsive";
import "swiper/css";
import "swiper/css/pagination";
import { useStateValue } from "../StateProviders";
import "../../App.css";
import { useNavigate } from 'react-router-dom';
import GoToTop from '../GoToTop';


// appointment table start

// appointment table end

// prescriptions table start

const Prescribecolumns = [
  {
    title: " Name",
    dataIndex: "Name",
    key: "name",
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "date",
  },

  {
    title: "Attachment",
    dataIndex: "Attachment",
    key: "Attachment",
    render: (text) => (
      <>
        {/* <button className='patient_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button> */}
        <a href={text} target="_blank">
          {" "}
          <button className="patient_dashboard_table_view_btn">
            <i class="fa-regular fa-eye"></i> View
          </button>
        </a>
      </>
    ),
  },
];

// prescriptions table end

// medical records table start

const Medicalcolumn = [
  {
    title: "Record ID",
    dataIndex: "id",
    key: "ID",
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
  },
  {
    title: "TitleName",
    dataIndex: "TitleName",
    key: "TitleName",
  },
  {
    title: "SaloonName",
    dataIndex: "HospitalName",
    key: "HospitalName",
  },
  {
    title: "Purpose",
    dataIndex: "Symptoms",
    key: "Description",
  },
  {
    title: "Attachment",
    dataIndex: "Attachment",
    key: "Attachment",
    render: (text) => (
      <>
        {/* <button className='patient_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button> */}
        <a href={text} target="_blank">
          {" "}
          <button className="patient_dashboard_table_view_btn">
            <i class="fa-regular fa-eye"></i> View
          </button>
        </a>
      </>
    ),
  },
  {
    title: "Created",
    dataIndex: "CreateBy",
    key: "Created",
  },
  // {
  //   title: '',
  //   key: 'tags',
  //   dataIndex: 'tags',
  //   render: (button) => (
  //     <>
  //       <button className='patient_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button>
  //       <button className='patient_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button>
  //     </>
  //   ),
  // },
];

// medical records table end

// billing table start

const BillingColumn = [
  {
    title: "Invoice No",
    dataIndex: "Invoice",
    key: "InvoiceNo",
  },
  // {
  //   title: 'Doctor',
  //   dataIndex: 'Doctor',
  //   key: 'Doctor',
  // },
  {
    title: "Amount",
    dataIndex: "Amount",
    key: "Amount",
  },
  {
    title: "Paid On",
    dataIndex: "Paidon",
    key: "PaidOn",
  },
  {
    title: "",
    key: "tags",
    dataIndex: "tags",
    render: (button) => (
      <>
        <button className="patient_dashboard_table_print_btn me-2">
          <i class="fa-solid fa-print"></i> Print
        </button>
        <button className="patient_dashboard_table_view_btn">
          <i class="fa-regular fa-eye"></i> View
        </button>
      </>
    ),
  },
];
const data3 = [
  {
    key: "1",
    InvoiceNo: "#MR-0010",
    Doctor: "Jeo Back",
    Amount: "$450",
    PaidOn: "14 Nov 2019",
  },
  {
    key: "2",
    InvoiceNo: "#MR-0010",
    Doctor: "Jeo Back",
    Amount: "$450",
    PaidOn: "14 Nov 2019",
  },
  {
    key: "3",
    InvoiceNo: "#MR-0010",
    Doctor: "Jeo Back",
    Amount: "$450",
    PaidOn: "14 Nov 2019",
  },
];

// billing table end

function PatientDashboard() {
  const Appointmentcolumns = [
    {
      title: "Appt Date",
      dataIndex: "Date",
      key: "age",
      render: (text, record) => (
        <>
          <div className="patient_dashboard_table_appdate_date">{text}</div>
          <div className="patient_dashboard_table_appdate_time">
            {record["Slot"]}
          </div>
        </>
      ),
    },
    {
      title: "Booking Date",
      dataIndex: "BookingDate",
      key: "address",
    },
    {
      title: "Purpose",
      dataIndex: "Purpose",
      key: "Purpose",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "address",
    },
    // {
    //   title: 'Follow Up',
    //   dataIndex: 'FollowUp',
    //   key: 'address',
    // },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, record) => (
        <>
          {text === 0 ? (
            <button className="patient_dashboard_table_pending_btn">
              Pending
            </button>
          ) : text === -1 ? (
            <button className="patient_dashboard_table_reject_btn">
              Reject
            </button>
          ) : text === 1 ? (
            <button className="patient_dashboard_table_confirm_btn">
              Confirm
            </button>
          ) : text === 2 ? (
            <button className="patient_dashboard_table_confirm_btn">
              Confirm
            </button>
          ) : null}
        </>
      ),
    },
    {
      title: "Bill",
      key: "Status",
      dataIndex: "Status",
      render: (text, record) => (
        <>
          {text === -1 ? (
            <button className="patient_dashboard_table_reject_btn" disabled>
              N/A
            </button>
          ) : text === 0 ? (
            <button className="patient_dashboard_table_pending_btn" disabled>
              N/A
            </button>
          ) : text === 1 ? (
            <button
              className="patient_dashboard_table_pending_btn"
              onClick={() => {
                var Url = window.API_PREFIX + "booking/payfees";
                fetch(Url, {
                  method: "POST",
                  headers: {
                    "Content-type": "application/json",
                  },
                  body: JSON.stringify({
                    Token: localStorage.getItem("DToken"),
                    BookingId: record.BookingId,
                    Amount: record.Amount,
                  }),
                })
                  .then((resp) => resp.json())
                  .then((data) => {
                    console.log(data);
                    if (data["status"] === "1") {
                      const options = {
                        //    key: __DEV__ ? 'rzp_test_QoYTwM9rHNMFDE' : 'rzp_live_24bObjPtorMPgm',
                        key: "rzp_test_QoYTwM9rHNMFDE",
                        amount: 20 * 100,
                        amount: Number(record.Amount) * 100,
                        order_id: data["Payment_id"],
                        // name: data['Name'],

                        description: "Thank you for payment",
                        // image: { ArihantLogo },
                        handler: function (response) {
                          var Url =
                            window.API_PREFIX + "booking/verify_payment";

                          fetch(Url, {
                            method: "POST",
                            headers: {
                              "Content-type": "application/json",
                            },
                            body: JSON.stringify({
                              Token: localStorage.getItem("DToken"),
                              razorpay_order_id: response.razorpay_order_id,
                              razorpay_payment_id: response.razorpay_payment_id,
                              razorpay_signature: response.razorpay_signature,
                              BookingId: record.BookingId,
                            }),
                          })
                            .then((resp) => resp.json())
                            .then((Revdata) => {
                              console.log(Revdata);
                              if (Revdata["status"] == "1") {
                                var Url =
                                  window.API_PREFIX +
                                  "patient/view_medicalrecords";
                                fetch(Url, {
                                  method: "POST",
                                  headers: {
                                    "Content-type": "application/json",
                                  },
                                  body: JSON.stringify({
                                    Token: localStorage.getItem("DToken"),
                                  }),
                                })
                                  .then((resp) => resp.json())
                                  .then((data) => {
                                    console.log(data);
                                    if (data["status"] === "1") {
                                      setuserMedicalData([...data["records"]]);
                                    }
                                  });
                                setDashboardOption([false, false, false, true]);
                              }
                            });
                        },
                        prefill: {
                          name: "",
                          email: "",
                          phone_number: "",
                        },
                      };
                      const paymentObject = new window.Razorpay(options);
                      paymentObject.open();
                    }
                  });
              }}
            >
              Pay Amount
            </button>
          ) : text === 2 ? (
            <>
              <button className="patient_dashboard_table_confirm_btn" disabled>
                Paid
              </button>
              {/* <button className='patient_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button> */}
            </>
          ) : null}
        </>
      ),
    },
  ];

  const addmedicalrecordpopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "160px 450px",
  };
  const [{ IsRegister }, dispatch] = useStateValue();
  const [userAppointmentData, setuserAppointmentData] = useState([]);
  const [userPrescribeData, setuserPrescribeData] = useState([]);
  const [userMedicalData, setuserMedicalData] = useState([]);
  const [addMedicalRecord, setAddMedicalRecord] = useState(false);
  const [TitleName, setTitleName] = useState("");
  const [HospitalName, setHospitalName] = useState("");
  const [Symptoms, setSymptoms] = useState("");
  const [RecordDate, setRecordDate] = useState("");
  const [MedicalRecord, setMedicalRecord] = useState();

  const [userBillingData, setuserBillingData] = useState([]);

  useEffect(() => {
    // dispatch({type:"Register"})
    var Url = window.API_PREFIX + "patient/userwiseappointment";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setuserAppointmentData([...data["appointment"]]);
        }
      });
  }, []);

  const [DashboardOption, setDashboardOption] = useState([
    true,
    false,
    false,
    false,
  ]);



  // responsive table start

  let navigate = useNavigate();
  const [ActiveUserTab, SetActiveUserTab] = useState([
    true,
    false,
    false,
    false,
    false
  ]);

  const appoinmentsdata = [
    {
      id: 1,
      bookingpurpose: "Hair Loss",
      bookingdate: "25/04/2000",
      payment: "₹120",
      apptdate: "26/04/2000",
      appttime: "10:00-12:00 AM",
      bookingstatus: (
        <>
          <button className='patient_dashboard_table_pending_btn_mobile'>pending</button>

          {/* ----------------after succesfull payment show confirmed otherwise show rejected------------ */}

          {/* <button className='patient_dashboard_table_confirm_btn_mobile'>Confirmed</button> */}
          {/* <button className='patient_dashboard_table_reject_btn_mobile'>Rejected</button> */}
        </>
      ),
      paymentstatus: (
        <>
          <button className='patient_dashboard_table_CancelNA_btn_mobile'>N/A</button>

          {/* -------------------after booking show payamout btn and after paymentsuccessful show paid button--------------- */}

          {/* <button className='patient_dashboard_table_pay_btn_mobile'>Pay Amount</button> */}
          {/* <button className='patient_dashboard_table_pay_btn_mobile'>Paid</button> */}
        </>
      )
    }
  ]

  const prescriptiondata = [
    {
      id: 1,
      name: "Ramesh Patel",
      date: "25/04/2000",
      attach: (
        <button className='patient_dashboard_table_print_btn_mobile'><i class="fa-solid fa-print"></i></button>
      )
    }
  ]

  const medicalrecordsdata = [
    {
      id: 1,
      recordid: "012",
      date: "25/04/2000",
      title: "hyuujjn",
      hospitalname: " savita hostp",
      Symptoms: "fever",
      pdfname: "peril.pdf",
      Attachment: (
        <button className="patient_dashboard_table_view_btn">
          <i class="fa-regular fa-eye"></i> View
        </button>
      ),
      Created: "iuhhuih",
    }
  ]

  const billingsdata = [
    {
      id: 1,
      invoiceno: "0212",
      amount: "₹120",
      paidon: "24/04/2020",
      view: (
        <>
          <button className='patient_dashboard_table_view_btn_mobile me-2'><i class="fa-regular fa-eye"></i></button>
        </>
      ),
      print: (
        <button className='patient_dashboard_table_print_btn_mobile'><i class="fa-solid fa-print"></i></button>
      )
    }
  ]

  const profiledata = [
    {

    }
  ]


  return (
    <>
      {/* breadcrumbs-start */}
      <div className="patient_dashboard_breadcrumb_main_division">
        <div className="patient_dashboard_breadcrumb_division">
          <p className="patient_dashboard_breadcrumb_title" onClick={() => { navigate('/') }}>home</p>
          <p className="patient_dashboard_breadcrumb_title1">/</p>
          <p className="patient_dashboard_breadcrumb_title2">dashboard</p>
        </div>
        <div>
          <h1 className="patient_dashboard_breadcrumb_title_main">dashboard</h1>
        </div>
      </div>
      {/* breadcrumbs-end */}

      <div className="container-fuild">
        <div className="slidebar_and_component">
          <PatientSidebar />
          {/* <Patientsidebarresponsive /> */}

          <div className="component_division">
            <div className="patient_dashboard_main_division">
              <div class="patient_bashboard_tab_division">
                <div
                  className={
                    DashboardOption[0]
                      ? "col patient_bashboard_tabs_title_name_division_active"
                      : "col patient_bashboard_tabs_title_name_division"
                  }
                  onClick={() => {
                    var Url = window.API_PREFIX + "patient/userwiseappointment";
                    fetch(Url, {
                      method: "POST",
                      headers: {
                        "Content-type": "application/json",
                      },
                      body: JSON.stringify({
                        Token: localStorage.getItem("DToken"),
                      }),
                    })
                      .then((resp) => resp.json())
                      .then((data) => {
                        console.log(data);
                        if (data["status"] === "1") {
                          setuserAppointmentData([...data["appointment"]]);
                        }
                      });
                    setDashboardOption([true, false, false, false]);
                  }}
                >
                  <div class="patient_bashboard_tabs_title_name">
                    Appointments
                  </div>
                </div>
                <div
                  className={
                    DashboardOption[1]
                      ? "col patient_bashboard_tabs_title_name_division_active"
                      : "col patient_bashboard_tabs_title_name_division"
                  }
                  onClick={() => {
                    var Url = window.API_PREFIX + "admin/view_prescription";
                    fetch(Url, {
                      method: "POST",
                      headers: {
                        "Content-type": "application/json",
                      },
                      body: JSON.stringify({
                        Token: localStorage.getItem("DToken"),
                      }),
                    })
                      .then((resp) => resp.json())
                      .then((data) => {
                        console.log(data);
                        if (data["status"] === "1") {
                          setuserPrescribeData([...data["data"]]);
                        }
                      });
                    setDashboardOption([false, true, false, false]);
                  }}
                >
                  <div class="patient_bashboard_tabs_title_name">
                    Beauty Tips
                  </div>
                </div>
                <div
                  className={
                    DashboardOption[2]
                      ? "col patient_bashboard_tabs_title_name_division_active"
                      : "col patient_bashboard_tabs_title_name_division"
                  }
                  onClick={() => {
                    var Url = window.API_PREFIX + "patient/view_medicalrecords";
                    fetch(Url, {
                      method: "POST",
                      headers: {
                        "Content-type": "application/json",
                      },
                      body: JSON.stringify({
                        Token: localStorage.getItem("DToken"),
                      }),
                    })
                      .then((resp) => resp.json())
                      .then((data) => {
                        console.log(data);
                        if (data["status"] === "1") {
                          setuserMedicalData([...data["records"]]);
                        }
                      });
                    setDashboardOption([false, false, true, false]);
                  }}
                >
                  <div class="patient_bashboard_tabs_title_name">Offers</div>
                </div>
                <div
                  className={
                    DashboardOption[3]
                      ? "col patient_bashboard_tabs_title_name_division_active"
                      : "col patient_bashboard_tabs_title_name_division"
                  }
                  onClick={() => {
                    var Url = window.API_PREFIX + "patient/billingpatientwise";
                    fetch(Url, {
                      method: "POST",
                      headers: {
                        "Content-type": "application/json",
                      },
                      body: JSON.stringify({
                        Token: localStorage.getItem("DToken"),
                      }),
                    })
                      .then((resp) => resp.json())
                      .then((data) => {
                        console.log(data);
                        if (data["status"] === "1") {
                          setuserBillingData([...data["Bill"]]);
                        }
                      });
                    setDashboardOption([false, false, false, true]);
                  }}
                >
                  <div class="patient_bashboard_tabs_title_name">Billing</div>
                </div>
              </div>

              {DashboardOption[0] ? (
                <div className="patient_dashboard_table_division">
                  <Table
                    columns={Appointmentcolumns}
                    dataSource={userAppointmentData}
                  />
                </div>
              ) : DashboardOption[1] ? (
                <div className="patient_dashboard_table_division">
                  <Table
                    columns={Prescribecolumns}
                    dataSource={userPrescribeData}
                  />
                </div>
              ) : DashboardOption[2] ? (
                <>
                  <div
                    className="patient_medicalrecords_add_records_section"
                    onClick={() => {
                      setAddMedicalRecord(true);
                    }}
                  >
                    <button
                      type="button"
                      className=" patient_medical_records_add_records_button btn"
                    >
                      Add Medical Records
                    </button>
                    {/* This css is in Patient Medical record */}
                  </div>
                  <div className="patient_dashboard_table_division">
                    <Table
                      columns={Medicalcolumn}
                      dataSource={userMedicalData}
                    />
                  </div>
                </>
              ) : (
                <div className="patient_dashboard_table_division">
                  <Table columns={BillingColumn} dataSource={userBillingData} />
                </div>
              )}
            </div>
          </div>

          <div className="Dashboard_table">

            {ActiveUserTab[0] ? (
              <div className="dashboard_table_appoinment_table_main">
                <div className="dashboard_table_appoinment_title">Appointments</div>
                {appoinmentsdata.map((appoinments) => (
                  <div className='patient_dashbord_mobile_appointments_main_division'>
                    <div className='patient_dashboard_mobile_table_appoinment_table_purpose_division'>
                      <div className='patient_dashboard_mobile_table_title'>Purpose</div>
                      <div className='patient_dashboard_mobile_table_purpose_value'>{appoinments.bookingpurpose}</div>
                    </div>
                    <div className='patient_dashboard_mobile_table_appoinment_table_time_division'>
                      <div>
                        <div className='patient_dashboard_mobile_table_title'>BOOKING DATE</div>
                        <div className='patient_dashboard_mobile_table_appoinment_booked_date'>{appoinments.bookingdate}</div>
                      </div>
                      <div className="patient_dashboard_card_second_div">
                        <div className='patient_dashboard_mobile_table_title'>AMOUNT</div>
                        <div className='patient_dashboard_mobile_table_appoinment_bill'>{appoinments.payment}</div>
                      </div>
                    </div>
                    <div className="patient_dashboard_mobile_table_appoinment_table_time_division">
                      <div>
                        <div className='patient_dashboard_mobile_table_title'>APPT. DATE</div>
                        <div className='patient_dashboard_mobile_table_appoinment_table_date'>{appoinments.apptdate}</div>
                      </div>
                      <div className="patient_dashboard_card_second_div">
                        <div className='patient_dashboard_mobile_table_title'>TIME</div>
                        <div className='patient_dashboard_mobile_table_appoinment_table_time'>{appoinments.appttime}</div>
                      </div>
                    </div>
                    <div className='patient_dashboard_mobile_date_status_division'>
                      <div>
                        <div className='patient_dashboard_mobile_table_title'>BOOKING STATUS</div>
                        <div>{appoinments.bookingstatus}</div>
                      </div>
                      <div className="patient_dashboard_card_second_div">
                        <div className='patient_dashboard_mobile_table_title'>PAYMENT STATUS</div>
                        <div>{appoinments.paymentstatus}</div>
                      </div>
                    </div>

                  </div>
                ))}
                <div className="add_aapoinment_button_mobile_main_div">
                  <button className={window.location.pathname.includes('/booking') ? 'btn responsive_sidebar_dashboard_title_division_active_mobile' : " btn responsive_sidebar_dashboard_title_division_mobile"}
                    onClick={() => {

                      navigate('/booking')
                    }}><i class="fa-solid fa-plus"></i></button>
                </div>
              </div>
            ) : null}

            {ActiveUserTab[1] ? (
              <div className="dashboard_table_prescription_table_main">
                <div className="dashboard_table_appoinment_title">Beauty Tips</div>
                {prescriptiondata.map((prescription) => (
                  <div className='patient_dashboard_table_priscription_table'>
                    <div>
                      <div className='patient_dashboard_table_appoinment_table_name_title'>{prescription.name}</div>
                      <div className='patient_dashboard_table_appoinment_table_first_div'>{prescription.date}</div>
                    </div>
                    <div>
                      <div> {prescription.attach}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            {ActiveUserTab[2] ? (
              <div className="patient">
                <div className="dashboard_table_appoinment_title">offers</div>
                {medicalrecordsdata.map((medicalrecords) => (
                  <div className="patient_dashboard_table_medicalrecords_table">
                    <div className="patient_dashboard_table_medicalrecords_table_first_div">
                      <div>
                        <div className="patient_dashboard_table_appoinment_table_time_date_div_date">#{medicalrecords.recordid}</div>
                        <div className='patient_dashboard_mobile_table_title'>TITLE</div>
                        <div className="patient_dashboard_table_appoinment_table_second_div">{medicalrecords.Symptoms}</div>
                      </div>
                      <div>
                        <button className='patient_dashboard_table_edit_btn_mobile'>Edit</button>
                      </div>
                    </div>

                    <div className="patient_dashboard_table_appoinment_table_time_date">
                      <div className="patient_dashboard_mobile_table_title">DATE</div>
                      <div className="patient_dashboard_table_appoinment_table_second_div">
                        {medicalrecords.date}
                      </div>
                    </div>
                    <div className="patient_dashboard_table_appoinment_table_time_date">
                      <div className="patient_dashboard_mobile_table_title">
                        SALON NAME
                      </div>
                      <div className="patient_dashboard_table_appoinment_table_second_div">
                        {medicalrecords.hospitalname}
                      </div>
                    </div>
                    <div className="patient_dashboard_table_appoinment_table_time_date">
                      <div className="patient_dashboard_mobile_table_title">
                        PURPOSE
                      </div>
                      <div className="patient_dashboard_table_appoinment_table_second_div">
                        {medicalrecords.title}
                      </div>
                    </div>
                    <div className="patient_dashboard_table_appoinment_table_time_date">
                      <div className="patient_dashboard_mobile_table_title">
                        Attachment
                      </div>
                      <div className="d-flex">
                        <div>{medicalrecords.pdfname}</div>
                        {medicalrecords.Attachment}
                      </div>
                    </div>
                    <div className="patient_dashboard_table_appoinment_table_time_date">
                      <div className="patient_dashboard_mobile_table_title">
                        Created
                      </div>
                      <div className="patient_dashboard_table_appoinment_table_second_div">
                        {medicalrecords.Created}
                      </div>
                    </div>
                  </div>
                ))}

                {/* <div className="patient_medicalrecords_add_records_section">
                  <button
                    type="button"
                    className=" 
                    
                    btn"
                    onClick={() => {
                      setAddMedicalRecord(true);
                    }}
                  >
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </div> */}
              </div>
            ) : null}

            {ActiveUserTab[3] ? (
              <div className="patient_table_billing_table_main">
                <div className="dashboard_table_appoinment_title">billings</div>
                {billingsdata.map((billings) => (
                  <div className="patient_dashboard_table_billings_table">
                    <div className="saloon_dashboard_table_appointment_table_leftside_main">
                      <div className="patient_dashboard_mobile_table_title">
                        AMOUNT
                      </div>
                      <div className="patient_dashboard_table_appoinment_table_first_div_amount">
                        {billings.amount}
                      </div>
                      <div className="patinent_dashboard_left_first_section">
                        <div className="patient_dashboard_table_appoinment_table_invoice_no">
                          # {billings.invoiceno}
                        </div>
                        <div>|</div>
                        <div className="patient_dashboard_table_appoinment_table_first_div">
                          {billings.paidon}
                        </div>
                      </div>
                    </div>
                    <div className="saloon_dashboard_tabke_appointment_table_rightside_main_division">
                      <div className="patient_dashboard_table_appoinment_table_view">
                        {billings.view}
                      </div>
                      <div className="patient_dashboard_table_appoinment_table_view">
                        {billings.print}
                      </div>

                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            {ActiveUserTab[4] ? (
              <div className="patient_table_billing_table_main">
                <div className="dashboard_table_appoinment_title">Profile</div>
                {profiledata.map((profile) => (
                  <div className='responsive_sidebar_main_division'>
                    <div className='responsive_sidebar_division'>
                      <div className={window.location.pathname.includes('/profilesetting') ? 'responsive_sidebar_dashboard_title_division_active' : "responsive_sidebar_dashboard_title_division_active"}
                        onClick={() => { navigate('/profilesetting') }}>Profile Settings</div>
                      <div className='responsive_sidebar_img_profile_main_division'>
                        <div className='responsive_sidebar_img_profile_division'>
                          {/* <img className='Doctorsidebar_img_profile' src={patientdashboardimg}></img> */}
                        </div>
                      </div>
                      <div className='responsive_sidebar_profile_person_name'>Richard Wilson</div>
                      <div className='responsive_sidebar_birthdate_division'><i class="sidebar_birthdate_icon_cake_location fa-solid fa-cake-candles"></i> 24 Jul 1983, 38 years</div>
                      <div className='responsive_sidebar_location_division'><i class="sidebar_birthdate_icon_cake_location fa-solid fa-location-dot"></i> Newyork, USA</div>
                    </div>
                    <div className={window.location.pathname.includes('/changepassword') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
                      onClick={() => {

                        navigate('/changepassword')
                      }}
                    ><i class="fa-solid fa-lock me-2"></i> Change Password </div>
                    <div className={window.location.pathname.includes('/') ? 'sidebar_dashboard_title_division' : "sidebar_dashboard_title_division"}
                      onClick={() => {

                        navigate('/PrivacyAndPolicy')
                      }}><i class="fa-regular fa-file me-2"></i> Privacy Policy</div>

                    <div className={window.location.pathname.includes('/') ? 'sidebar_dashboard_title_division' : "sidebar_dashboard_title_division"}
                      onClick={() => {

                        navigate('/TermsAndConditions')
                      }}><i class="fa-regular fa-file me-2"></i> Tearms and Conditions</div>


                    <div className='sidebar_dashboard_title_division'
                      onClick={() => {
                        localStorage.clear()
                        dispatch({
                          type: "Logout"
                        })
                        navigate("")
                      }}
                    ><i class="fa-solid fa-right-from-bracket me-2"></i>Logout</div>
                  </div>
                ))}
              </div>
            ) : null}

          </div>
        </div>

        <Popup
          contentStyle={addmedicalrecordpopup}
          modal
          open={addMedicalRecord}
          onClose={() => {
            setAddMedicalRecord(false);
          }}
        >
          <div className="Add_medical_records_popup_div">
            <div className="add_medical_records_popuop_header">
              <h5 className="add_medical_records_records_title">
                Add Medical Records
              </h5>
              <i
                onClick={() => {
                  setAddMedicalRecord(false);
                }}
                class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
              ></i>
            </div>
            <div className="add_medical_records_popup_body">
              <div className="popup_body_div_two">
                <div className="popup_body_name_patient_section row">
                  <div className="col-12 col-md-6">
                    <p className="popup_body_name_title">Title Name</p>
                    <input
                      type="text"
                      name="description"
                      class="addmedical_pop_text_feild form-control"
                      placeholder="Enter Title Name"
                      onChange={(e) => {
                        setTitleName(e.target.value);
                      }}
                    />
                  </div>
                  {/* <div className='col-12 col-md-6'>
                                                        <p className='popup_body_patient_title'>Patient</p>
                                                        <select class=" popup_input_tags form-select" aria-label="Default select example">
                                                            <option selected>Open this select menu</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div> */}
                </div>
                <br></br>
                <div className="popup_body_hospital_name_section">
                  <p className="popup_body_hospital_title">Hospital Name</p>
                  <input
                    type="text"
                    name="description"
                    class=" addmedical_pop_text_feild form-control"
                    placeholder="Enter Name Here.."
                    onChange={(e) => {
                      setHospitalName(e.target.value);
                    }}
                  />
                </div>
                <br></br>
                <div>
                  <p className="popup_body_upload_title">Upload</p>
                  <div class="  input-group">
                    <input
                      class=" form-control"
                      id="inputGroupFile02"
                      style={{ cursor: "pointer" }}
                      type="file"
                      name="myImage"
                      //  accept='image/png/jpeg'
                      onChange={(event) => {
                        setMedicalRecord(event.target.files[0]);
                      }}
                    />
                    <label class="input-group-text" for="inputGroupFile02">
                      Upload
                    </label>
                  </div>
                </div>
                <br></br>
                <div>
                  <p className="popup_body_symptoms_title">Symptoms</p>
                  <input
                    type="text"
                    name="description"
                    class=" addmedical_pop_text_feild form-control"
                    placeholder="Fever,Fatigue"
                    onChange={(e) => {
                      setSymptoms(e.target.value);
                    }}
                  />
                </div>
                <br></br>
                <div>
                  <p className="popup_body_symptoms_title ">Date</p>
                  <input
                    className=" col-12 col-md-5 popup_body_date_section"
                    type="date"
                    onChange={(e) => {
                      setRecordDate(e.target.value);
                    }}
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Basic example"
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider> */}
                </div>
                <br></br>
                <div className="popup_submit_button_section">
                  <button
                    type="button"
                    className="popup_submit_button btn"
                    onClick={() => {
                      setAddMedicalRecord(false);
                      var Url =
                        window.API_PREFIX + "patient/add_medicalrecords";

                      var uploadData = new FormData();
                      uploadData.append(
                        "Token",
                        localStorage.getItem("DToken")
                      );
                      uploadData.append("TitleName", TitleName);
                      uploadData.append("HospitalName", HospitalName);
                      uploadData.append("Symptoms", Symptoms);
                      uploadData.append("RecordDate", RecordDate);
                      uploadData.append("Attachment", MedicalRecord);
                      fetch(Url, {
                        method: "POST",
                        // headers: {
                        //     'Content-type': 'application/json',
                        // },
                        body: uploadData,
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data);
                          if (data["status"] === "1") {
                            alert("Medical Record Added");
                            var tmp = userMedicalData;
                            tmp.push({
                              id: data["id"],
                              Date: RecordDate,
                              Symptoms: Symptoms,
                              Attachment: data["Pdf"],
                              CreateBy: data["createdby"],
                              HospitalName: HospitalName,
                              TitleName: TitleName,
                            });
                            setuserMedicalData([...tmp]);
                            setAddMedicalRecord(false);
                          }
                        });
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <div className="Dashboard_table_first_sction_mobile">
          <button
            type="button"
            className={
              ActiveUserTab[0]
                ? "btn patient_dashboard_btn_active"
                : " btn patient_dashboard_btn"
            }
            onClick={() => {
              SetActiveUserTab([true, false, false, false, false]);
            }}
          >
            <i class="fa-solid fa-calendar-days fa-xl"></i>
          </button>
          <button
            type="button"
            className={
              ActiveUserTab[1]
                ? "btn patient_dashboard_btn_active"
                : " btn patient_dashboard_btn"
            }
            onClick={() => SetActiveUserTab([false, true, false, false, false])}
          >
            <i class="fa-solid fa-file-prescription fa-xl"></i>
          </button>
          <button
            type="button"
            className={
              ActiveUserTab[2]
                ? "btn patient_dashboard_btn_active"
                : " btn patient_dashboard_btn"
            }
            onClick={() => SetActiveUserTab([false, false, true, false, false])}
          >
            <i class="fa-solid fa-book-medical fa-xl"></i>
          </button>
          <button
            type="button"
            className={
              ActiveUserTab[3]
                ? "btn patient_dashboard_btn_active"
                : " btn patient_dashboard_btn"
            }
            onClick={() => SetActiveUserTab([false, false, false, true, false])}
          >
            <i class="fa-solid fa-file-invoice fa-xl"></i>
          </button>
          <button type="button"
            className={
              ActiveUserTab[4]
                ? 'btn patient_dashboard_btn_active'
                : ' btn patient_dashboard_btn'
            }
            onClick={() => SetActiveUserTab([false, false, false, false, true])}
          ><i class="fa-regular fa-user fa-xl"></i></button>
        </div>
        <GoToTop/>
      </div>
    </>
  );
}
export default PatientDashboard;
