import './Footer.css';
import '../../App.css';
import footerlogo from '../../media/mainlogo.png'
import { useNavigate } from 'react-router-dom';

function Footer() {
    let navigate = useNavigate();
    return (
        <>
            <div>
                <div className='footer_main_division'>
                    <div className='footer_joinourmail_division'>
                        <div className='footer_joinourmail_title'>join our email list</div>
                        <div className='footer_joinourmail_submit_division'>
                            <input class="form-control footer_joinourmail_input" type="search" placeholder="Search" aria-label="Search" />
                            <button class="footer_joinourmail_btn" type="submit">submit</button>
                        </div>
                    </div>
                    <div className='footer_address_main_division'>
                        <div className='footer_address_logo_division'><img className='footer_address_logo' src={footerlogo} onClick={() => { navigate('') }} /></div>
                        <div className='footer_address_title'>
                            <a href={'https://goo.gl/maps/YCwQDd9HpKgVPXiW8'} target="_blank">FF-9, Sun Rise Shops & Flats, Near Vrundavan Crossing, Waghodia Road, Vadodara-390025</a></div>
                    </div>
                    {/* <div className='footer_social_main_division'> */}
                        <div className='footer_social_division'>
                            <a href={"https://twitter.com/nyshasolutions/"} target="_blank">
                                {" "}
                                <div className='footer_social_icon_dvsn'><i class="fa-brands fa-twitter"></i></div>
                            </a>

                            <a href={"https://www.facebook.com/NYSHASOLUTIONS/"} target="_blank">
                                {" "}
                                <div className='footer_social_icon_dvsn'><i class="fa-brands fa-facebook-f"></i></div>
                            </a>
                            <div className='footer_social_icon_dvsn'><i class="fa-brands fa-google-plus-g"></i></div>
                            <div className='footer_social_icon_dvsn'><i class="fa-brands fa-pinterest"></i></div>
                            <div className='footer_social_icon_dvsn'><i class="fa-solid fa-camera"></i></div>
                            <div className='footer_social_icon_dvsn'><i class="fa-solid fa-wifi"></i></div>
                        </div>
                    {/* </div> */}
                </div>
                <div className=" footer_copyright">
                    <div className="footer_copy">
                        <div className="copyright_text">
                            <label className="footer_copyright_label">
                                Copyright <i class="fa-regular fa-copyright"></i> 2022 |
                                Developed by{" "}
                                <a
                                    className="footer_copyright_a"
                                    href='https://nyshasolutions.com/'
                                >
                                    <span className='footer_copyright_label_title_name'>Nysha Solutions</span>
                                </a>
                            </label>
                        </div>
                    </div>
                    <div className="footer_copy_pr">
                        <div className="copyright_menu">
                            <label className="footer_copyright_policy_label">
                                <a className="footer_tc"
                                 onClick={() => {navigate('/TermsAndConditions')}}>
                                    Terms & Conditions
                                </a>
                                <a className="footer_tc"> | </a>
                                <a className="footer_tc" 
                                 onClick={() => {navigate('/PrivacyAndPolicy') }}>
                                    Privacy Policy
                                </a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;