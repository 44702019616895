import { useEffect, useState } from 'react';
import headerlogo from '../media/mainlogo.png';
import headerprofileimage from '../media/image.jpg';
import headerlogoname from '../media/Component 1.png';
import { useStateValue } from './StateProviders';
// import Patientsidebarresponsive from './Patient/Patientsidebarresponsive';
import '../Component/Header/Header';
import { Button, Drawer } from 'antd';
import { useNavigate } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';

// import rightdoctor from './homepageimg/rightdoctor.jpg';
import babu from '../media/babu.jpg'


function Simpledoctorheader() {

    const [profile, setProfile] = useState(true)
    const [openMenu, setOpenmenu] = useState(false)
    const [{ IsLogin, IsRegister, DIsLogin }, dispatch] = useStateValue();
    const [balanceState, usebalanceState] = useState(false);
    const [userdata, setuserdata] = useState({});
    const [activeMenu, setActiveMenu] = useState([false, false, false, false])
    let navigate = useNavigate();
    useEffect(() => {
        console.log(">>>Header  IsLogin")
        // dispatch({ type: 'Register' })

        // if (!IsLogin) {
        var Url = window.API_PREFIX + 'admin/doctordetail'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {

                dispatch({ type: 'DLogin' })



                setuserdata({ ...data['data'] })
            }
            else {
                // navigate('/login')
            }
        })
        // }
    }, [DIsLogin])

    // useEffect(() => {
    //     console.log(">>>Header  Register")
    //     // dispatch({ type: 'Register' })

    //     // if (!IsLogin) {
    //     var Url = window.API_PREFIX + 'patient/check_user'
    //     fetch(Url, {
    //         method: 'POST',
    //         headers: {
    //             'Content-type': 'application/json',
    //         },
    //         body: JSON.stringify(
    //             {
    //                 Token: localStorage.getItem('DToken'),
    //             }
    //         ),
    //     }).then(resp => resp.json()).then(data => {
    //         console.log(data)
    //         if (data['status'] === "1") {

    //             if (!data['IsRegister']) {
    //                 dispatch({ type: 'NotRegister' })


    //             }
    //             else {
    //                                 dispatch({ type: 'Register' })
    //                             }



    //             setuserdata({ ...data })
    //         }
    //         else {
    //             // navigate('/login')
    //         }

    //         console.log(IsLogin)
    //         console.log(IsRegister)
    //     })
    //     // }
    // }, [IsRegister])


    // mobile responsive start
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    // mobile responsive start


    const options = [
        {
            userimg: babu,
            username: 'peril patel',
            apptime: '09:00 AM - 09:30 AM',
            apptdate: '24 MAR 2023'
        },
        {
            userimg: babu,
            username: 'ramesh patel',
            apptime: '09:30 AM - 10:00 AM',
            apptdate: '25 MAR 2023'
        },
        {
            userimg: babu,
            username: 'suresh patel',
            apptime: '09:30 AM - 10:00 AM',
            apptdate: '26 MAR 2023'
        },


    ];


    const [anchorEl, setAnchorEl] = useState(null);
    const oopen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (

        <>
            <div className="container-fuild">
                <div className="header_main_division">
                    {/* <div onMouseLeave={(e) => { setOpenmenu(false); }} className="col-md-7 header_menu_main_division">
                        <div onMouseEnter={(e) => {
                            setOpenmenu(true);
                        }}
                            className='header_menu_division_active'>
                            Doctor<i class="fa-solid fa-chevron-down mx-1"></i>
                        </div>
                        {openMenu ? (
                            <>
                                <div className='header_menu'>
                                    <div className='header_menu_name'>
                                        patient profile
                                    </div>
                                    <div className='header_menu_name'>
                                        doctor Dashboard
                                    </div>
                                </div>
                            </>
                        ) : null
                        }
                        <div className='header_menu_division'>
                            patients<i class="fa-solid fa-chevron-down mx-1"></i>
                        </div>
                    </div> */}
                    <div className='header_center_main_division'>
                        <div className={activeMenu[0] ? 'header_center_division_active' : 'header_center_division'}
                            onClick={() => {
                                setActiveMenu([true, false, false, false])
                                navigate('/admin/dashboard')
                            }}>
                            Dashboard
                        </div>

                        <div className={activeMenu[2] ? 'header_center_division_active' : 'header_center_division'}
                            onClick={() => {
                                setActiveMenu([false, false, true, false])
                                navigate('/admin/mycustomer')
                            }}>
                            My customer
                        </div>
                        <div className='header_logo_division' onClick={() => { navigate('/admin/dashboard') }}>
                            <div className='header_main_logo_main_dvsn'>
                                <img className='header_logo' src={headerlogo} />
                            </div>
                            <div className='header_main_logo_title_main_division'>
                                <img className='header_logo' src={headerlogoname} />

                            </div>
                        </div>
                        <div className={activeMenu[1] ? 'header_center_division_active' : 'header_center_division'}
                            onClick={() => {
                                setActiveMenu([false, true, false, false])
                                navigate('/admin/schedule-timing')
                            }}>
                            Schedule Timing
                        </div>
                        <div className={activeMenu[3] ? 'header_center_division_active' : 'header_center_division'}
                            onClick={() => {
                                setActiveMenu([false, false, false, true])
                                navigate('/admin/adminchangepassword')
                            }
                            }>
                            Change Password
                        </div>
                    </div>
                    {DIsLogin ?
                        <div className="header_contact_profile_main_division">
                            <div className='header_contact_main_division'>
                                <div>
                                    <Tooltip title="Notifications">
                                        <IconButton
                                            style={{ color: "white" }}
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={oopen ? 'long-menu' : undefined}
                                            aria-expanded={oopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        >
                                            <Badge badgeContent={25} color="primary">
                                                <i class="fa-solid fa-bell "></i>
                                            </Badge>

                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        className='notification_main_div'
                                        disableScrollLock={true}
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={oopen}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                // maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '35ch',
                                                // maxHeight:'5000px'
                                                height: '600px',
                                                marginRight: '10px',
                                                padding: '10px',
                                                borderRadius: '20px'
                                            },
                                        }}

                                    >
                                        <div className='doctor_header_notification_title'>Notifications</div>
                                        <div className='doctor_header_new_older_title'>Today</div>
                                        {options.map((option) => (
                                            <MenuItem
                                            //  onClick={handleClose} 

                                            >
                                                <div className='mt-2' >
                                                    <div className='doctor_header_notification_card_first_div'>
                                                        <div>
                                                            <img className='doctor_header_notification_patient_image' src={option.userimg}></img>
                                                        </div>
                                                        <div className='mx-2'>
                                                            <div className='doctor_header_notification_patient_name'>{option.username}</div>
                                                            <div className='doctor_header_notification_booking_time'>{option.apptime}</div>
                                                            <div className='doctor_header_notification_booking_date'>{option.apptdate}</div>
                                                        </div>
                                                    </div>
                                                    <div className='doctor_header_notification_btn_main_div'>
                                                        <button className='btn doctor_header_notification_accept_btn mt-2 '  >
                                                            {/* <i class="fa-solid fa-check"></i> */}
                                                            Accept
                                                        </button>
                                                        <button className='btn doctor_header_notification_cancel_btn mt-2 '>
                                                            {/* <i class="fa-solid fa-xmark"></i> */}
                                                            Reject
                                                        </button>
                                                    </div>
                                                    <div className='doctor_header_notification_time'>3 Minutes Ago</div>
                                                </div>
                                            </MenuItem>
                                        ))}

                                        <div className='doctor_header_new_older_title'>Previous</div>
                                    </Menu>
                                </div>
                            </div>
                            <div onClick={(() => { setProfile(!profile) }
                            )} className='header_profile_main_division'>
                                <div className='header_profile_image_division'>
                                    <img className=' header_profile_image' src={userdata['Image']}></img>
                                </div>
                                <div><i class={profile ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up header_profile_arrow_up_icon"}></i></div>
                                {!profile ? <div className='header_profile_division'>
                                    <div className='header_profile_division_header'>
                                        <div className='header_profile_header_profile_image_division'><img className='header_profile_header_profile_image' src={userdata['Image']}></img></div>
                                        <div>
                                            <p className='header_profile_header_name'>{userdata['Name']}</p>
                                            {/* <p className='header_profile_header_postname'>patient:{userdata['displayId']}</p> */}
                                        </div>
                                    </div>
                                    <div className='header_profile_list'
                                        onClick={() => {
                                            navigate('/admin/dashboard')
                                        }}
                                    ><i class="fa-solid fa-table-columns me-2"></i>  Dashboard</div>
                                    {/* <div className='header_profile_list'
                                onClick={() => {
                                    navigate('/doctor/profilesetting')
                                }}
                            ><i class="fa-solid fa-gears me-2"></i> Profile Settings</div> */}
                                    <div className='header_profile_list'
                                        onClick={() => {
                                            navigate('/admin/adminchangepassword')
                                        }}
                                    ><i class="fa-solid fa-lock me-2"></i> Change Password</div>
                                    <div className='header_profile_list'
                                        onClick={() => {
                                            localStorage.clear()

                                            dispatch({ type: 'DLogout' })

                                            navigate("/admin")
                                        }}
                                    ><i class="fa-solid fa-right-from-bracket me-2"></i> Log Out</div>
                                </div> : null}
                            </div>
                        </div>

                        :

                        <div className='header_login_signup_btn_division'>
                            <button className='btn header_login_signup_btn'
                                onClick={() => {
                                    navigate('/admin')
                                    localStorage.clear()
                                    dispatch({ type: "Logout" })
                                    dispatch({ type: 'DLogout' })

                                }}
                            >login / signup</button>
                        </div>
                    }
                </div>
            </div>

            {/* <div className="container-fuild_mobile">
                <div className="header_main_division_mobile">

                    <div>
                        <span className='bar_icon' onClick={showDrawer}>
                            <span className='bar_span_one'></span>
                            <span className='bar_span_two'></span>
                            <span className='bar_span_three'></span>
                        </span>
                    </div>
                    <Drawer
                        title="Hello,"
                        width="260"
                        placement="left"
                        onClose={onClose}
                        open={open}
                        getContainer={false}
                    >

                        <div>
                            <div className='header_center_main_division_mobile'>

                                <div className={activeMenu[0] ? 'header_center_division_active_mobile' : 'header_center_division_mobile'}
                                    onClick={() => {
                                        setActiveMenu([true, false, false, false])
                                        navigate('/admin/dashboard')
                                        setOpen(false)
                                    }}>
                                    Dashboard
                                </div>
                                <div className={activeMenu[1] ? 'header_center_division_active_mobile' : 'header_center_division_mobile'}
                                    onClick={() => {
                                        setActiveMenu([false, true, false, false])
                                        navigate('/admin/mycustomer')
                                        setOpen(false)
                                    }}>
                                    My Customer
                                </div>
                                <div className={activeMenu[2] ? 'header_center_division_active_mobile' : 'header_center_division_mobile'}
                                    onClick={() => {
                                        setActiveMenu([false, false, true, false])
                                        navigate('/admin/schedule-timing')
                                        setOpen(false)
                                    }}>
                                    Schedule Timing
                                </div>

                                <div className={activeMenu[3] ? 'header_center_division_active_mobile' : 'header_center_division_mobile'}
                                    onClick={() => {
                                        setActiveMenu([false, false, false, true])
                                        navigate('/admin/adminchangepassword')
                                        setOpen(false)
                                    }
                                    }>
                                    Change Password
                                </div>


                            </div>
                        </div>
                    </Drawer>



                 

                    <div className='header_logo_main_division_mobile'>
                        <div className='header_main_logo_main_dvsn'>
                            <img className='header_logo' src={headerlogo} onClick={() => { navigate('/') }} />
                        </div>
                        <div className='header_main_logo_title_main_division'>
                            <img className='header_logo' src={headerlogoname} />

                        </div>
                    </div>


                    {!DIsLogin ? <div className='header_login_signup_btn_division_mobile'>
                        <button className='btn header_login_signup_btn_mobile'
                            onClick={() => {
                                navigate('/doctor/')
                                localStorage.clear()
                                dispatch({ type: "DLogout" })
                            }}
                        >login / signup</button>
                    </div> :
                        <div className="header_contact_profile_main_division_mobile">
                            <div onClick={(() => { setProfile(!profile) }
                            )} className='header_profile_main_division_mobile'>
                                <div className='header_profile_image_division_mobile'>
                                    <img className=' header_profile_image_mobile' src={userdata['Image']}></img>
                                </div>
                                <div><i class={profile ? "fa-solid fa-chevron-down header_profile_arrow_up_icon_mobile" : "fa-solid fa-chevron-up header_profile_arrow_up_icon_mobile"}></i></div>
                                {!profile ? <div className='header_profile_division_mobile'>
                                    <div className='header_profile_division_header_mobile'>
                                        <div className='header_profile_header_profile_image_division_mobile'><img className='header_profile_header_profile_image_mobile' src={userdata['Image']}></img></div>
                                        <div>
                                            <p className='header_profile_header_name_mobile'>{userdata['Name']}</p>
                                        
                                        </div>
                                    </div>
                                    <div className='header_profile_list_mobile'
                                        onClick={() => {
                                            navigate('/doctor/dashboard')
                                        }}
                                    ><i class="fa-solid fa-table-columns me-2"></i>  Dashboard</div>
                   
                                    <div className='header_profile_list_mobile'
                                        onClick={() => {
                                            navigate('/doctor/doctorchangepassword')
                                        }}
                                    ><i class="fa-solid fa-lock me-2"></i> Change Password</div>
                                    <div className='header_profile_list'
                                        onClick={() => {
                                            localStorage.clear()
                                            dispatch({
                                                type: "DLogout"
                                            })


                                            navigate("/doctor/")


                                        }}
                                    ><i class="fa-solid fa-right-from-bracket me-2"></i> Log Out</div>
                                </div> : null}
                            </div>
                        </div>}
                </div>
            </div> */}

        </>
    )
}
export default Simpledoctorheader;