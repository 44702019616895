import { useEffect, useState } from 'react';
import headerlogo from '../../media/mainlogo.png';
import headerlogoname from '../../media/Component 1.png';
import headerprofileimage from '../../media/image.jpg';
import { useStateValue } from '../StateProviders';
import Patientsidebarresponsive from '../Customer/Customersidebarresponsive';
import './Header.css';
import '../../App.css';
import { Button, Drawer } from 'antd';
import { useNavigate } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';


function Header() {

    const [anchorEl, setAnchorEl] = useState(null);
    const oopen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const [profile, setProfile] = useState(true)
    const [openMenu, setOpenmenu] = useState(false)
    const [{ IsLogin, IsRegister }, dispatch] = useStateValue();
    const [balanceState, usebalanceState] = useState(false);
    const [userdata, setuserdata] = useState({});
    const [activeMenu, setActiveMenu] = useState([false, false, false, false])
    let navigate = useNavigate();
    useEffect(() => {
        console.log(">>>Header  IsLogin")
        // dispatch({ type: 'Register' })

        // if (!IsLogin) {
        var Url = window.API_PREFIX + 'patient/check_user'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {

                dispatch({ type: 'Login' })



                setuserdata({ ...data })
            }
            else {
                // navigate('/login')
            }
            console.log(IsLogin)
            console.log(IsRegister)
        })
        // }
    }, [IsLogin])

    useEffect(() => {
        console.log(">>>Header  Register")
        // dispatch({ type: 'Register' })

        // if (!IsLogin) {
        var Url = window.API_PREFIX + 'patient/check_user'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {

                if (!data['IsRegister']) {
                    dispatch({ type: 'NotRegister' })


                }
                else {
                    dispatch({ type: 'Register' })
                }



                setuserdata({ ...data })
            }
            else {
                // navigate('/login')
            }

            console.log(IsLogin)
            console.log(IsRegister)
        })
        // }
    }, [IsRegister])


    // mobile responsive start
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    // mobile responsive start

    return (

        <>
            <header className="container-fuild">
                <div className="header_main_division">

                    {/* <div onMouseLeave={(e) => { setOpenmenu(false); }} className="col-md-7 header_menu_main_division">
                        <div onMouseEnter={(e) => {
                            setOpenmenu(true);
                        }}
                            className='header_menu_division_active'>
                            Doctor<i class="fa-solid fa-chevron-down mx-1"></i>
                        </div>
                        {openMenu ? (
                            <>
                                <div className='header_menu'>
                                    <div className='header_menu_name'>
                                        patient profile
                                    </div>
                                    <div className='header_menu_name'>
                                        doctor Dashboard
                                    </div>
                                </div>
                            </>
                        ) : null
                        }
                        <div className='header_menu_division'>
                            patients<i class="fa-solid fa-chevron-down mx-1"></i>
                        </div>
                    </div> */}




                    <div className='header_center_main_division'>

                        <div className='header_tab_left_one'>


                            <div className={activeMenu[0] ? 'header_center_division_active' : 'header_center_division'}
                                onClick={() => {
                                    setActiveMenu([true, false, false, false])
                                    navigate('/')
                                }}>
                                home
                            </div>

                            <div className={activeMenu[2] ? 'header_center_division_active' : 'header_center_division'}
                                onClick={() => {
                                    setActiveMenu([false, false, true, false])
                                    navigate('/aboutus')
                                }}>
                                about
                            </div>



                            <div className='header_logo_division' onClick={() => { navigate('/') }}>
                                <div className='header_main_logo_main_dvsn'>
                                    <img className='header_logo' src={headerlogo} />
                                </div>
                                <div className='header_main_logo_title_main_division'>
                                    <img className='header_logo' src={headerlogoname} />

                                </div>
                            </div>

                            <div className={activeMenu[1] ? 'header_center_division_active' : 'header_center_division'}
                                onClick={() => {
                                    setActiveMenu([false, true, false, false])
                                    navigate('/booking')
                                }}>
                                booking
                            </div>
                            <div className={activeMenu[3] ? 'header_center_division_active' : 'header_center_division'}
                                onClick={() => {
                                    setActiveMenu([false, false, false, true])
                                    navigate('/ContactUs')
                                }
                                }>
                                contact us
                            </div>
                        </div>

                        <div className='header_tab_left_two'>

                            <div className='d-flex justify-space-between align-items-center'>
                                <div>
                                    <span className='bar_icon' onClick={showDrawer}>
                                        <span className='bar_span_one'></span>
                                        <span className='bar_span_two'></span>
                                        <span className='bar_span_three'></span>
                                    </span>
                                </div>
                                <Drawer
                                    title="Hello,  patel"
                                    width="260"
                                    placement="left"
                                    onClose={onClose}
                                    open={open}
                                    getContainer={false}
                                >

                                    <div>
                                        <div className='header_center_main_division_mobile'>

                                            <div className={activeMenu[0] ? 'header_center_division_active_mobile' : 'header_center_division_mobile'}
                                                onClick={() => {
                                                    setActiveMenu([true, false, false, false])
                                                    navigate('/')
                                                    setOpen(false)
                                                }}><i class="fa-solid fa-house me-2"></i>
                                                Home
                                            </div>
                                            <div className={activeMenu[1] ? 'header_center_division_active_mobile' : 'header_center_division_mobile'}
                                                onClick={() => {
                                                    setActiveMenu([false, true, false, false])
                                                    navigate('/booking')
                                                    setOpen(false)
                                                }}><i class="fa-regular fa-calendar-check me-2"></i>
                                                Booking
                                            </div>
                                            <div className={activeMenu[2] ? 'header_center_division_active_mobile' : 'header_center_division_mobile'}
                                                onClick={() => {
                                                    setActiveMenu([false, false, true, false])
                                                    navigate('/aboutus')
                                                    setOpen(false)
                                                }}><i class="fa-solid fa-users me-2"></i>
                                                About
                                            </div>

                                            <div className={activeMenu[3] ? 'header_center_division_active_mobile' : 'header_center_division_mobile'}
                                                onClick={() => {
                                                    setActiveMenu([false, false, false, true])
                                                    navigate('/ContactUs')
                                                    setOpen(false)
                                                }
                                                }><i class="fa-solid fa-phone me-2"></i>
                                                Contact Us
                                            </div>


                                        </div>
                                    </div>
                                </Drawer>
                                <div className='header_logo_division' onClick={() => { navigate('/') }}>
                                    <div className='header_main_logo_main_dvsn'>
                                        <img className='header_logo' src={headerlogo} />
                                    </div>
                                    <div className='header_main_logo_title_main_division'>
                                        <img className='header_logo' src={headerlogoname} />

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                    {IsRegister && IsLogin ?
                        <div className="header_contact_profile_main_division">
                            <div className='header_contact_main_division'>
                                <div className='d-flex align-items-center'>
                                    <div className='header_contact_division'>
                                        <p className='header_contact_title'>Contact</p>
                                        <p className='header_contact_number'>{userdata['DocNo']}</p>
                                    </div>
                                </div>

                                <div>
                                    <Tooltip title="Notifications">
                                        <IconButton
                                            style={{ color: "white" }}
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={oopen ? 'long-menu' : undefined}
                                            aria-expanded={oopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        >
                                            <Badge badgeContent={2} color="primary">
                                                <i class="fa-solid fa-bell "></i>
                                            </Badge>

                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        className='notification_main_div'
                                        disableScrollLock={true}
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={oopen}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                width: '30ch',
                                                height: '300px',
                                                marginRight: '10px',
                                                padding: '10px',
                                                borderRadius: '20px'
                                            },
                                        }}
                                    >
                                        <div className='doctor_header_notification_title'>Notifications</div>

                                        {/* {options.map((option) => (   ))} */}
                                        <MenuItem
                                        //  onClick={handleClose} 

                                        >
                                            <div>- Your Appoinment Is successfully<br></br> Booked</div>
                                        </MenuItem>
                                        <MenuItem
                                        //  onClick={handleClose} 

                                        >

                                            <div>- Your Appoinment Is Rejected,<br></br> Call admin For Further Details.</div>
                                        </MenuItem>



                                    </Menu>
                                </div>


                            </div>
                            <div onClick={(() => { setProfile(!profile) }
                            )} className='header_profile_main_division'>
                                <div className='header_profile_image_division'>
                                    <img className=' header_profile_image' src={userdata['Img']}></img>
                                </div>
                                <div><i class={profile ? "fa-solid fa-chevron-down header_profile_arrow_up_icon" : "fa-solid fa-chevron-up header_profile_arrow_up_icon"}></i></div>
                                {!profile ? <div className='header_profile_division'>
                                    <div className='header_profile_division_header'>
                                        <div className='header_profile_header_profile_image_division'><img className='header_profile_header_profile_image' src={userdata['Img']}></img></div>
                                        <div>
                                            <p className='header_profile_header_name'>{userdata['Name']}</p>
                                            <p className='header_profile_header_postname'>Customer:{userdata['displayId']}</p>
                                        </div>
                                    </div>
                                    <div className='header_profile_list'
                                        onClick={() => {
                                            navigate('/dashboard')
                                        }}
                                    ><i class="fa-solid fa-table-columns me-2"></i>  Dashboard</div>
                                    <div className='header_profile_list'
                                        onClick={() => {
                                            navigate('/profilesetting')
                                        }}
                                    ><i class="fa-solid fa-gears me-2"></i> Profile Settings</div>
                                    <div className='header_profile_list'
                                        onClick={() => {
                                            navigate('/changepassword')
                                        }}
                                    ><i class="fa-solid fa-lock me-2"></i> Change Password</div>
                                    <div className='header_profile_list'
                                        onClick={() => {
                                            localStorage.clear()
                                            dispatch({
                                                type: "Logout"
                                            })
                                            dispatch({ type: 'NotRegister' })

                                            navigate("")
                                        }}
                                    ><i class="fa-solid fa-right-from-bracket me-2"></i> Log Out</div>
                                </div> : null}
                            </div>
                        </div>

                        :

                        <div className='header_login_signup_btn_division'>
                            <button className='btn header_login_signup_btn'
                                onClick={() => {
                                    navigate('/login')
                                    localStorage.clear()
                                    dispatch({ type: "Logout" })
                                    dispatch({ type: 'NotRegister' })

                                }}
                            >login / signup</button>
                        </div>
                    }
                </div>
            </header>




        </>
    )
}
export default Header;