import './SaloonPatientProfile.css';
import 'antd/dist/antd.css';
import { Space, Table, Tag } from 'antd';
import React, { useState, useEffect } from 'react';
import patientdashboardimg from '../../media/image.jpg';
import Popup from 'reactjs-popup';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './Sidebar.css'
import '../../App.css';
import GoToTop from '../GoToTop';

// appointment table start


// appointment table end


// prescriptions table start



// prescriptions table end



// medical records table start

const doctor_patient_profile_columns2 = [
    {
        title: 'ID',
        dataIndex: 'ID',
        key: 'ID',

    },
    {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date',
    },
    {
        title: 'Description',
        dataIndex: 'Description',
        key: 'Description',
    },
    {
        title: 'Attachment',
        dataIndex: 'Attachment',
        key: 'Attachment',
    },
    {
        title: 'Created',
        dataIndex: 'Created',
        key: 'Created',
    },
    {
        title: '',
        key: 'tags',
        dataIndex: 'tags',
        render: (button) => (
            <>
                <button className='doctor_patient_profile_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button>
                <button className='doctor_patient_profile_table_view_btn me-2'><i class="fa-regular fa-eye"></i> View</button>
                <button className='doctor_patient_profile_table_edit_btn me-2'><i class="fa-solid fa-pen-to-square"></i> Edit</button>
                <button className='doctor_patient_profile_table_cancel_btn'><i class="fa-solid fa-trash"></i> Cancel</button>

            </>
        ),
    },

];


// medical records table end



// billing table start

const doctor_patient_profile_columns3 = [
    {
        title: 'Invoice No',
        dataIndex: 'InvoiceNo',
        key: 'InvoiceNo',

    },
    {
        title: 'Doctor',
        dataIndex: 'Doctor',
        key: 'Doctor',
    },
    {
        title: 'Amount',
        dataIndex: 'Amount',
        key: 'Amount',
    },
    {
        title: 'Paid On',
        dataIndex: 'PaidOn',
        key: 'PaidOn',
    },
    {
        title: '',
        key: 'tags',
        dataIndex: 'tags',
        render: (button) => (
            <>
                <button className='doctor_patient_profile_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button>
                <button className='doctor_patient_profile_table_view_btn me-2'><i class="fa-regular fa-eye"></i> View</button>
                <button className='doctor_patient_profile_table_edit_btn me-2'><i class="fa-solid fa-pen-to-square"></i> Edit</button>
                <button className='doctor_patient_profile_table_cancel_btn'><i class="fa-solid fa-trash"></i> Cancel</button>

            </>
        ),
    },

];


// billing table end

function DoctorPatientProfile() {
    const addmedicalrecordpopup = {
        borderRadius: "10px",
        backgroundColor: "white",
        // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
        borderradius: "5px",
        padding: "0px",
        width: "100%",
        height: "max-content",
        margin: "160px 450px"
    }
    const addprescribtionpopup =
    {
        borderRadius: "10px",
        backgroundColor: "white",
        // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
        borderradius: "5px",
        padding: "0px",
        width: "100%",
        height: "max-content",
        margin: "160px 450px"
    }
    const editpricepopup = {
        borderRadius: "10px",
        backgroundColor: "white",
        // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
        borderradius: "5px",
        padding: "0px",
        width: "100%",
        height: "max-content",
        margin: "160px 450px"
    }



    const params = useParams()
    let navigate = useNavigate()
    const { PID } = params
    const { state } = useLocation()


    const [addMedicalRecord, setAddMedicalRecord] = useState(false)
    const [editpricePop, seteditpricePop] = useState(false)

    const [docAppointmentData, setdocAppointmentData] = useState([])
    const [docPrescribeData, setdocPrescribeData] = useState([])
    const [docMedicalData, setdocMedicalData] = useState([])
    const [docBillingData, setdocBillingData] = useState([])
    const [editPriceIndex, seteditPriceIndex] = useState('')
    const [eacheditprice, seteacheditprice] = useState('')

    const [TitleName, setTitleName] = useState("")
    const [HospitalName, setHospitalName] = useState("")
    const [Symptoms, setSymptoms] = useState("")
    const [RecordDate, setRecordDate] = useState("")
    const [MedicalRecord, setMedicalRecord] = useState()


    const [addprescribtion, setaddprescribtion] = useState(false)
    const [PrescName, setPrescName] = useState("")
    const [PrescRecordDate, setPrescRecordDate] = useState("")
    const [PrescRecord, setPrescRecord] = useState()



    const [DashboardOption, setDashboardOption] = useState([true, false, false, false])





    useEffect(() => {
        console.log(state)
        var Url = window.API_PREFIX + 'admin/userwiseappointment'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: PID


                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setdocAppointmentData([...data['appointment']])
            }
        })




    }, [])



    function Pending_Payment(Paymentindex) {
        var Url = window.API_PREFIX + 'admin/Paybydoctor'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: docAppointmentData[Paymentindex]['BookingId'],
                    PatientId: PID


                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                var tmp = docAppointmentData
                tmp[Paymentindex]['Status'] = 2
                setdocAppointmentData([...tmp])
            }
        })

    }

    function UpdatePrice() {

    }

    function AcceptRequest(Requestindex) {
        var Url = window.API_PREFIX + 'booking/approve_booking'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: docAppointmentData[Requestindex]['BookingId']


                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                var tmp = docAppointmentData
                tmp[Requestindex]['Status'] = 1
                setdocAppointmentData([...tmp])
            }
        })


    }


    function RejectRequest(Requestindex) {


        var Url = window.API_PREFIX + 'booking/reject_booking'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                    Id: docAppointmentData[Requestindex]['BookingId'],




                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                var tmp = docAppointmentData
                tmp[Requestindex]['Status'] = -1
                setdocAppointmentData([...tmp])
            }
        })

    }

    const Prescribecolumns = [
        {
            title: ' Name',
            dataIndex: 'Name',
            key: 'name',
            render: (text, record, index) => (
                <div
                    // onClick={() => {
                    //     navigate("/doctor/addprescription", {
                    //         state: {
                    //             PID: PID,
                    //             ADDType: false,
                    //             Title: record.Title,
                    //             Date: record.Date

                    //         }
                    //     })
                    // }}

                    onClick={() => {

                    }}
                >
                    {text}

                </div>
            ),
        },

        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'date',

        },

        {
            title: 'Attachment',
            dataIndex: 'Attachment',
            key: 'Attachment',
            render: (text) => (
                <>
                    {/* <button className='patient_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button> */}
                    <a href={text} target="_blank"> <button className='patient_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button></a>
                </>
            ),
        },

        // {
        //     title: '',
        //     key: 'tags',
        //     dataIndex: 'tags',
        //     render: (button) => (
        //         <>
        //             <button className='doctor_patient_profile_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button>
        //             <button className='doctor_patient_profile_table_view_btn me-2'><i class="fa-regular fa-eye"></i> View</button>
        //             <button className='doctor_patient_profile_table_edit_btn me-2'><i class="fa-solid fa-pen-to-square"></i> Edit</button>
        //             <button className='doctor_patient_profile_table_cancel_btn'><i class="fa-solid fa-trash"></i> Cancel</button>

        //         </>
        //     ),
        // },

    ];

    const Medicalcolumn = [
        {
            title: 'Record ID',
            dataIndex: 'id',
            key: 'ID',

        },
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
        },
        {
            title: 'TitleName',
            dataIndex: 'TitleName',
            key: 'TitleName',
        },
        {
            title: 'SaloonName',
            dataIndex: 'HospitalName',
            key: 'HospitalName',
        },
        {
            title: 'Purpose',
            dataIndex: 'Symptoms',
            key: 'Description',
        },
        {
            title: 'Attachment',
            dataIndex: 'Attachment',
            key: 'Attachment',
            render: (text) => (
                <>
                    {/* <button className='patient_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button> */}
                    <a href={text} target="_blank"> <button className='patient_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button></a>
                </>
            ),
        },
        {
            title: 'Created',
            dataIndex: 'CreateBy',
            key: 'Created',
        },
        // {
        //   title: '',
        //   key: 'tags',
        //   dataIndex: 'tags',
        //   render: (button) => (
        //     <>
        //       <button className='patient_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button>
        //       <button className='patient_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button>
        //     </>
        //   ),
        // },

    ];
    const BillingColumn = [
        {
            title: 'Invoice No',
            dataIndex: 'Invoice',
            key: 'InvoiceNo',

        },
        // {
        //   title: 'Doctor',
        //   dataIndex: 'Doctor',
        //   key: 'Doctor',
        // },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'Amount',
        },
        {
            title: 'Paid On',
            dataIndex: 'Paidon',
            key: 'PaidOn',
        },
        {
            title: '',
            key: 'tags',
            dataIndex: 'tags',
            render: (button) => (
                <>
                    <button className='patient_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button>
                    <button className='patient_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button>
                </>
            ),
        },

    ];


    const doctor_patient_profile_columns = [
        {
            title: 'Appt Date',
            dataIndex: 'Date',
            key: 'age',
            render: (text, record) => (
                <>
                    <div className='doctor_patient_profile_table_datetime_div'>
                        <div className='doctor_patient_profile_table_appdate_date'>{text}</div>
                        <div className='doctor_patient_profile_table_appdate_time'>{record['Slot']}</div>
                    </div>
                </>
            ),
        },
        {
            title: 'Booking Date',
            dataIndex: 'BookingDate',
            key: 'address',
        },
        {
            title: 'Purpose',
            dataIndex: 'Purpose',
            key: 'Purpose',
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'address',
        },

        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (text, record, index) => (

                //     <button className='doctor_patient_profile_table_confirm_btn'>Confirm</button>
                //         <button className='doctor_patient_profile_table_Cancelled_btn'>Cancelled</button>
                // <button className='doctor_patient_profile_table_Pending_btn'>Pending</button>
                // <button className='doctor_patient_profile_table_Completed_btn'>Completed</button>

                <>
                    {text === 0 ? <><button className='btn doctor_patient_profile_table_confirm_btn'
                        onClick={() => {
                            AcceptRequest(index)
                        }}
                    >Accept</button>
                        <button className='btn patient_dashboard_table_reject_btn'
                            onClick={() => {
                                RejectRequest(index)
                            }}
                        >Reject</button></> :
                        text === 1 || text === 2 ? <button className='btn patient_dashboard_table_confirm_btn' disabled>Accepted</button> :
                            text === -1 ? <button className='btn patient_dashboard_table_reject_btn' disabled>Rejected</button> :
                                null


                    }
                </>




            ),
        },
        {
            title: 'Payment',
            key: 'tags',
            dataIndex: 'tags',
            render: (text, record, index) => (
                <>
                    {/* <button className='doctor_patient_profile_table_edit_btn me-2'><i class="fa-solid fa-pen-to-square"></i> Edit Price</button>
                      <button className='doctor_patient_profile_table_cancel_btn me-2'><i class="fa-solid fa-trash"></i> </button> */}


                    {
                        record['Status'] === 1 ? <><button className='doctor_patient_profile_table_edit_btn me-2'
                            onClick={() => {
                                seteditpricePop(true)
                                seteditPriceIndex(index)
                                seteacheditprice(record['Amount'])


                            }}
                        ><i class="fa-solid fa-pen-to-square"></i> Edit Price</button>
                            <button className='btn doctor_patient_profile_table_Pending_btn me-2'
                                onClick={() => {
                                    Pending_Payment(index)
                                }}
                            > Pending Payment</button></> :
                            record['Status'] === 2 ? <button className='btn patient_dashboard_table_confirm_btn' disabled>Paid</button> :
                                record['Status'] === 0 ? <>
                                    <button className='doctor_patient_profile_table_edit_btn me-2'
                                        onClick={() => {
                                            seteditpricePop(true)
                                            seteditPriceIndex(index)
                                            seteacheditprice(record['Amount'])


                                        }}
                                    ><i class="fa-solid fa-pen-to-square"></i> Edit Price</button>
                                    <button className='btn doctor_patient_profile_table_Pending_btn me-2' disabled>Pending Request</button>
                                </> :
                                    record['Status'] === -1 ? <button className='btn patient_dashboard_table_reject_btn' disabled>N/A</button> : null

                    }
                    {/* <button className='doctor_patient_profile_table_Reschedule_btn'><i class="fa-solid fa-clock"></i> Reschedule</button> */}
                </>
            ),
        },

    ];





    // responsive table start

    const [ActiveUserTab, SetActiveUserTab] = useState([true, false, false, false, false])

    const appoinmentsdata = [
        {
            id: 1,
            appttime: '7:00-12:00 AM',
            apptdate: '26/04/2000',
            bookingdate: '25/04/2000',
            bookingpurpose: 'Fever',
            amount: '45000',
            status: <>
                <button className='btn doctor_patientprofile_tabel_accept_btn_mobile'><i class="fa-solid fa-check"></i></button>
                <button className='btn doctor_patientprofile_tabel_cancel_btn_mobile'><i class="fa-solid fa-xmark"></i></button>
                {/* <button className='btn patient_dashboard_table_confirm_btn' >Accepted</button> */}
                {/* <button className='btn patient_dashboard_table_reject_btn'>Rejected</button> */}
            </>,
            payment:

                <><button className='doctor_patient_profile_table_edit_btn'
                    onClick={() => {
                        seteditpricePop(true)



                    }}><i class="fa-solid fa-pen-to-square"></i></button>
                    <button className='btn doctor_patient_profile_table_Pending_btn' disabled>Pending Req</button>
                    {/* <button className='btn doctor_patient_profile_table_Pending_btn me-2'> Pending Payment</button> */}
                    {/* <button className='btn patient_dashboard_table_confirm_btn' disabled>Paid</button> */}
                    {/* <button className='btn patient_dashboard_table_reject_btn' disabled>N/A</button> */}
                </>
        }

    ]


    const prescriptiondata = [
        {
            id: 1,
            name: 'Ramesh Patel',
            date: '25/04/2000',
            attachment: <button className='patient_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i></button>,
        }

    ]

    const medicalrecordsdata = [
        {
            id: 1,
            recordid: '012',
            date: '25/04/2000',
            title: 'hyuujjn',
            hospitalname: 'savita hospital',
            Symptoms: 'fever',
            Attachment: <button className='patient_dashboard_table_view_btn'><i class="fa-regular fa-eye"></i> View</button>,
            Created: 'iuhhuih'
        }

    ]


    const billingsdata = [
        {
            id: 1,
            invoiceno: '0212',
            amount: '222412',
            paidon: '24/04/2020',
            print: <>
                <button className='doc_patient_profile_billings_view'><i class="fa-regular fa-eye"></i></button>
            </>,
            view: <>
                <button className='doc_patient_profile_billings_print'><i class="fa-solid fa-print"></i></button>
            </>

        }

    ]








    // responsive table end





    return (
        <>
            {/* breadcrumbs-start */}
            <div className='doctor_patient_profile_breadcrumb_main_division'>
                <div className='doctor_patient_profile_breadcrumb_division'>
                    <p className='doctor_patient_profile_breadcrumb_title'
                        onClick={() => {
                            navigate("/doctor/dashboard")
                        }}
                    >home</p>
                    <p className='doctor_patient_profile_breadcrumb_title1'>/</p>
                    <p className='doctor_patient_profile_breadcrumb_title2' onClick={() => { navigate("/dashboard") }}>profile</p>
                </div>
                <div>
                    <h1 className='doctor_patient_profile_breadcrumb_title_main'>profile</h1>
                </div>
            </div>
            {/* breadcrumbs-end */}

            <div className="container-fuild">
                <div className='slidebar_and_component'>
                    <div className='doctor_patient_profile_sidebar_main_division'>
                        <div className='doctor_patient_profile_sidebar_division'>
                            <div className='doctor_patient_profile_sidebar_main_sub_division'>
                                <div className='doctor_patient_profile_sidebar_img_profile_main_division'><div className='doctor_patient_profile_sidebar_img_profile_division'><img className='doctor_patient_profile_sidebar_img_profile' src={state['Img']}></img></div></div>
                                <div className='doctor_patient_profile_sidebar_profile_person_name'>{state['name']}</div>
                                <div className='doctor_patient_profile_sidebar_patient_id_division'>
                                    <div className='doctor_patient_profile_sidebar_patient_id_name'>Customer ID:</div>
                                    <div className='doctor_patient_profile_sidebar_patient_id_value'>{state['displayId']}</div>
                                </div>
                                <p className='doctor_patient_profile_sidebar_location_division'><i class="doctor_patient_profile_sidebar_birthdate_icon_cake_location fa-solid fa-location-dot"></i> {state['Address']}</p>
                            </div>

                            <div className='docter_patient_profile_deetails_division'>
                                <div className='docter_patient_profile_deetails_title_name_division'>
                                    <p className='doctor_patient_profile_user_mo_number_title'>Phone</p>
                                    <p className='doctor_patient_profile_user_mo_number'>{state['Phone']}</p>

                                </div>
                                <div className='docter_patient_profile_deetails_title_name_division'>
                                    <p className='doctor_patient_profile_user_mo_number_title'>Age</p>
                                    <p className='doctor_patient_profile_user_mo_number'>{state['Age_Gender']}</p>
                                </div>

                                <div className='docter_patient_profile_deetails_title_name_division'>
                                    <div className='doctor_patient_profile_user_mo_number_title'>Blood Group</div>
                                    <div className='doctor_patient_profile_user_mo_number'>{state['Bloodgroup']}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='component_division_doctor_patient_profile'>
                        <div className='doctor_patient_profile_main_division'>

                            <div class="patient_bashboard_tab_division">
                                <div className={DashboardOption[0] ? 'col patient_bashboard_tabs_title_name_division_active' : 'col patient_bashboard_tabs_title_name_division'}
                                    onClick={() => {
                                        var Url = window.API_PREFIX + 'admin/userwiseappointment'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                {
                                                    Token: localStorage.getItem('DToken'),
                                                    Id: PID


                                                }
                                            ),
                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                setdocAppointmentData([...data['appointment']])
                                            }
                                        })
                                        setDashboardOption([true, false, false, false])
                                    }}
                                >
                                    <div class="patient_bashboard_tabs_title_name">Appointments</div>
                                </div>
                                <div className={DashboardOption[1] ? 'col patient_bashboard_tabs_title_name_division_active' : 'col patient_bashboard_tabs_title_name_division'}

                                    onClick={() => {

                                        var Url = window.API_PREFIX + 'admin/view_prescription'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                {
                                                    Token: localStorage.getItem('DToken'),
                                                    PatientId: PID


                                                }
                                            ),
                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                setdocPrescribeData([...data['data']])
                                            }
                                        })
                                        setDashboardOption([false, true, false, false])
                                    }}
                                >
                                    <div class="patient_bashboard_tabs_title_name">Beauty Tips</div>
                                </div>
                                <div className={DashboardOption[2] ? 'col patient_bashboard_tabs_title_name_division_active' : 'col patient_bashboard_tabs_title_name_division'}

                                    onClick={() => {
                                        var Url = window.API_PREFIX + 'patient/view_medicalrecords'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                {
                                                    Token: localStorage.getItem('DToken'),
                                                    PatientId: PID


                                                }
                                            ),
                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                setdocMedicalData([...data['records']])
                                            }
                                        })
                                        setDashboardOption([false, false, true, false])
                                    }}
                                >
                                    <div class="patient_bashboard_tabs_title_name">Offers</div>
                                </div>
                                <div className={DashboardOption[3] ? 'col patient_bashboard_tabs_title_name_division_active' : 'col patient_bashboard_tabs_title_name_division'}

                                    onClick={() => {

                                        var Url = window.API_PREFIX + 'patient/billingpatientwise'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                {
                                                    Token: localStorage.getItem('DToken'),
                                                    PatientId: PID



                                                }
                                            ),
                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                setdocBillingData([...data['Bill']])
                                            }
                                        })
                                        setDashboardOption([false, false, false, true])
                                    }}
                                >

                                    <div class="patient_bashboard_tabs_title_name">Billing</div>
                                </div>
                            </div>
                            {DashboardOption[0] ?
                                <div className='doctor_patient_profile_table_division'><Table columns={doctor_patient_profile_columns} dataSource={docAppointmentData} /></div> :
                                DashboardOption[1] ? <>                            <div className='doctor_patient_profile_add_prescription_division'
                                    onClick={() => {
                                        // navigate("/doctor/addprescription", {
                                        //     state: {
                                        //         PID: PID,
                                        //         ADDType: true

                                        //     }
                                        // })
                                        setaddprescribtion(true)
                                    }}
                                >
                                    <button className='doctor_patient_profile_add_prescription_btn'>Add Beauty Tips</button>
                                </div>

                                    <div className='doctor_patient_profile_table_division'><Table columns={Prescribecolumns} dataSource={docPrescribeData} /></div></> :
                                    DashboardOption[2] ? <>
                                        <div className='doctor_patient_profile_add_prescription_division'>
                                            <button onClick={() => {
                                                setAddMedicalRecord(true)
                                                console.log('Popup open')
                                            }} className='doctor_patient_profile_add_prescription_btn btn'>Add Offers</button>



                                            {/* Edit Price Popup */}


                                        </div>


                                        <div className='doctor_patient_profile_table_division'><Table columns={Medicalcolumn} dataSource={docMedicalData} /></div></> :
                                        DashboardOption[3] ? <>

                                            {/* <div className='doctor_patient_profile_add_prescription_division'>
                                <button className='doctor_patient_profile_add_prescription_btn'>Add Billing</button>
                            </div> */}

                                            <div className='doctor_patient_profile_table_division'><Table columns={BillingColumn} dataSource={docBillingData} /></div>
                                        </> : null}

                            <Popup
                                contentStyle={addmedicalrecordpopup}
                                modal
                                open={addMedicalRecord}
                                onClose={() => { setAddMedicalRecord(false) }}
                            >
                                <div className='Add_medical_records_popup_div'>
                                    <div className='add_medical_records_popuop_header'>
                                        <h5 className='add_medical_records_records_title'>Add Offers</h5>
                                        <i onClick={() => { setAddMedicalRecord(false) }} class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"></i>
                                    </div>
                                    <div className='add_medical_records_popup_body'>
                                        <div className='popup_body_div_two'>
                                            <div className='popup_body_name_patient_section row'>
                                                <div className='col-12 col-md-6'>
                                                    <p className='popup_body_name_title'>Title Name</p>
                                                    <input type="text" name="description" class=" popup_input_tags form-control" placeholder="Enter Title Name"
                                                        onChange={(e) => {
                                                            setTitleName(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                {/* <div className='col-12 col-md-6'>
                                                        <p className='popup_body_patient_title'>Patient</p>
                                                        <select class=" popup_input_tags form-select" aria-label="Default select example">
                                                            <option selected>Open this select menu</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div> */}
                                            </div>
                                            <br></br>
                                            <div className='popup_body_hospital_name_section'>
                                                <p className='popup_body_hospital_title'>Saloon Name</p>
                                                <input type="text" name="description" class=" popup_input_tags form-control" placeholder="Enter Name Here.."
                                                    onChange={(e) => {
                                                        setHospitalName(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <br></br>
                                            <div>
                                                <p className='popup_body_upload_title'>Upload</p>
                                                <div class="  input-group">
                                                    <input class=" form-control" id="inputGroupFile02"
                                                        style={{ "cursor": "pointer" }}
                                                        type="file"
                                                        name="myImage"
                                                        //  accept='image/png/jpeg'
                                                        onChange={(event) => {

                                                            setMedicalRecord(event.target.files[0]);

                                                        }}

                                                    />
                                                    <label class="input-group-text" for="inputGroupFile02">Upload</label>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div>
                                                <p className='popup_body_symptoms_title'>Purpose</p>
                                                <input type="text" name="description" class=" popup_input_tags form-control" placeholder="Fever,Fatigue"
                                                    onChange={(e) => {
                                                        setSymptoms(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <br></br>
                                            <div>
                                                <p className='popup_body_symptoms_title '>Date</p>
                                                <input className=' col-12 col-md-5 popup_body_date_section' type="date"
                                                    onChange={(e) => {
                                                        setRecordDate(e.target.value)
                                                    }}
                                                />
                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Basic example"
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider> */}
                                            </div>
                                            <br></br>
                                            <div className='popup_submit_button_section'>
                                                <button type="button" className="popup_submit_button btn"
                                                    onClick={() => {
                                                        setAddMedicalRecord(false)

                                                        var Url = window.API_PREFIX + 'admin/add_medicalrecords'

                                                        var uploadData = new FormData();
                                                        uploadData.append("Token", localStorage.getItem("DToken"));
                                                        uploadData.append("TitleName", TitleName);
                                                        uploadData.append("HospitalName", HospitalName);
                                                        uploadData.append("Symptoms", Symptoms);
                                                        uploadData.append("RecordDate", RecordDate);
                                                        uploadData.append("Attachment", MedicalRecord);
                                                        uploadData.append("Id", PID);

                                                        fetch(Url, {
                                                            method: 'POST',
                                                            // headers: {
                                                            //     'Content-type': 'application/json',
                                                            // },
                                                            body: uploadData
                                                        }).then(resp => resp.json()).then(data => {
                                                            console.log(data)
                                                            if (data['status'] === "1") {
                                                                alert("Medical Record Added")
                                                                var tmp = docMedicalData
                                                                tmp.push(
                                                                    {

                                                                        "id": data['id'],
                                                                        "Date": RecordDate,
                                                                        "Symptoms": Symptoms,
                                                                        "Attachment": data['Pdf'],
                                                                        "CreateBy": data['createdby'],
                                                                        "HospitalName": HospitalName,
                                                                        "TitleName": TitleName

                                                                    }
                                                                )
                                                                setdocMedicalData([...tmp])

                                                            }
                                                        })
                                                    }}
                                                >Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Popup>

                            <Popup
                                contentStyle={addprescribtionpopup}
                                modal
                                open={addprescribtion}
                                onClose={() => { setaddprescribtion(false) }}
                            >
                                <div className='Add_medical_records_popup_div'>
                                    <div className='add_medical_records_popuop_header'>
                                        <h5 className='add_medical_records_records_title'>Add Beauty Tips</h5>
                                        <i onClick={() => { setaddprescribtion(false) }} class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"></i>
                                    </div>
                                    <div className='add_medical_records_popup_body'>
                                        <div className='popup_body_div_two'>
                                            <div className='popup_body_name_patient_section row'>
                                                <div className='col-12 col-md-6'>
                                                    <p className='popup_body_name_title'>Tips Name</p>
                                                    <input type="text" name="description" class=" popup_input_tags form-control" placeholder="Enter Title Name"
                                                        onChange={(e) => {
                                                            setPrescName(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                {/* <div className='col-12 col-md-6'>
                                                        <p className='popup_body_patient_title'>Patient</p>
                                                        <select class=" popup_input_tags form-select" aria-label="Default select example">
                                                            <option selected>Open this select menu</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div> */}
                                            </div>
                                            <br></br>

                                            <div>
                                                <p className='popup_body_upload_title'>Upload</p>
                                                <div class="  input-group">
                                                    <input class=" form-control" id="inputGroupFile02"
                                                        style={{ "cursor": "pointer" }}
                                                        type="file"
                                                        name="myImage"
                                                        //  accept='image/png/jpeg'
                                                        onChange={(event) => {

                                                            setPrescRecord(event.target.files[0]);

                                                        }}

                                                    />
                                                    <label class="input-group-text" for="inputGroupFile02">Upload</label>
                                                </div>
                                            </div>

                                            <div>
                                                <p className='popup_body_symptoms_title '>Date</p>
                                                <input className=' col-12 col-md-5 popup_body_date_section' type="date"
                                                    onChange={(e) => {
                                                        setPrescRecordDate(e.target.value)
                                                    }}
                                                />
                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Basic example"
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider> */}
                                            </div>
                                            <br></br>
                                            <div className='popup_submit_button_section'>
                                                <button type="button" className="popup_submit_button btn"
                                                    onClick={() => {
                                                        setaddprescribtion(false)

                                                        var Url = window.API_PREFIX + 'admin/add_prescription'

                                                        var uploadData = new FormData();
                                                        uploadData.append("Token", localStorage.getItem("DToken"));
                                                        uploadData.append("Name", PrescName);
                                                        uploadData.append("PrescRecordDate", PrescRecordDate);
                                                        uploadData.append("Attachment", PrescRecord);
                                                        uploadData.append("PatientId", PID);

                                                        fetch(Url, {
                                                            method: 'POST',
                                                            // headers: {
                                                            //     'Content-type': 'application/json',
                                                            // },
                                                            body: uploadData
                                                        }).then(resp => resp.json()).then(data => {
                                                            console.log(data)
                                                            if (data['status'] === "1") {
                                                                alert("Prescription Added")
                                                                var tmp = docPrescribeData
                                                                tmp.push(
                                                                    {

                                                                        "id": data['id'],
                                                                        "Date": PrescRecordDate,
                                                                        "Attachment": data['Pdf'],
                                                                        "Name": PrescName

                                                                    }
                                                                )
                                                                setdocPrescribeData([...tmp])

                                                            }
                                                        })
                                                    }}
                                                >Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Popup>

                            <Popup
                                contentStyle={editpricepopup}
                                modal
                                open={editpricePop}
                                onClose={() => { seteditpricePop(false) }}
                            >
                                <div className='Add_medical_records_popup_div'>
                                    <div className='add_medical_records_popuop_header'>
                                        <h5 className='add_medical_records_records_title'>Edit Price</h5>
                                        <i onClick={() => { seteditpricePop(false) }} class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"></i>
                                    </div>
                                    <div className='add_medical_records_popup_body'>
                                        <div className='popup_body_div_two'>
                                            <div className='popup_body_name_patient_section row'>
                                                <div className='col-12 col-md-6'>
                                                    <p className='popup_body_name_title'>Enter new price</p>
                                                    <input type="text" name="description" class=" popup_input_tags form-control" placeholder="Enter Price Name" value={eacheditprice}
                                                        onChange={(e) => {
                                                            seteacheditprice(e.target.value)

                                                        }}
                                                    ></input>
                                                </div>

                                            </div>




                                            <div className='popup_submit_button_section'>
                                                <button type="button" className="popup_submit_button btn"
                                                    onClick={() => {
                                                        var Url = window.API_PREFIX + 'admin/changebookingprice'

                                                        fetch(Url, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-type': 'application/json',
                                                            },
                                                            body: JSON.stringify(
                                                                {
                                                                    Token: localStorage.getItem('DToken'),
                                                                    Amount: eacheditprice,
                                                                    Id: docAppointmentData[editPriceIndex]['BookingId'],
                                                                    PID: PID
                                                                }
                                                            ),
                                                        }).then(resp => resp.json()).then(data => {
                                                            console.log(data)
                                                            if (data['status'] === "1") {
                                                                // var tmpApp = docAppointmentData
                                                                // console.log(eacheditprice)

                                                                // tmpApp[editPriceIndex]['Amount'] = eacheditprice
                                                                // console.log("desfdc",tmpApp[editPriceIndex]['Amount'])
                                                                // console.log(tmpApp)
                                                                setdocAppointmentData([...data['appointment']])
                                                                seteditpricePop(false)
                                                            }
                                                        })
                                                    }}
                                                >Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Popup>

                        </div>
                    </div>

                    <div className='doctor_patient_profile_mobile'>


                        {ActiveUserTab[0] ?
                            <div className='patient_profile_table_appoinment_table_main'>
                                <div className='dashboard_table_main_title'>Appoinments</div>
                                {appoinmentsdata.map((appoinments) => (
                                    <div className='doctor_patient_profile_appoinmnent_main_div'>
                                        <div className='doctor_patient_profile_purpose_div'>
                                            <div className='doctor_patient_profile_small_title'>PURPOSE</div>
                                            <div className='doctor_patient_profile_paragraph_title'>{appoinments.bookingpurpose}</div>
                                        </div>
                                        <div className='doctor_patient_profile_booking_date_div'>
                                            <div>
                                                <div className='doctor_patient_profile_small_title'>BOOKING DATE</div>
                                                <div className='doctor_patient_profile_big_title'>{appoinments.bookingdate}</div>
                                            </div>
                                            <div className='doctor_patient_second_div'>
                                                <div className='doctor_patient_profile_small_title'>AMOUNT</div>
                                                <div className='doctor_patient_profile_big_title'>{appoinments.amount}</div>
                                            </div>
                                        </div>
                                        <div className='doctor_patient_profile_booking_date_div'>
                                            <div>
                                                <div className='doctor_patient_profile_small_title'>APPT DATE.</div>
                                                <div className='doctor_patient_profile_big_title'>{appoinments.apptdate}</div>
                                            </div>
                                            <div className='doctor_patient_second_div'>
                                                <div className='doctor_patient_profile_small_title'>TIME</div>
                                                <div className='doctor_patient_profile_big_title'>{appoinments.time}</div>
                                            </div>
                                        </div>
                                        <div className='doctor_patient_profile_booking_date_div'>
                                            <div>
                                                <div className='doctor_patient_profile_small_title'>BOOKING STATUS</div>
                                                <div className='d-flex'>{appoinments.status}</div>
                                            </div>
                                            <div>
                                                <div className='doctor_patient_profile_small_title'>PAYMENT STATUS</div>
                                                <div className='d-flex'>{appoinments.payment}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> : null}


                        {ActiveUserTab[1] ?
                            <div className='patient_profile_table_prescription_table_main'>
                                <div className='dashboard_table_main_title'>Beauty Tips</div>
                                <div className='patient_profile_add_prescription_btn_div'>
                                    <button type="button" className=" doctor_patient_profile_add_prescription_btn_mobile btn" onClick={() => {
                                        setaddprescribtion(true)
                                    }}>Add Beauty Tips</button>
                                </div>
                                {prescriptiondata.map((prescription) => (

                                    <div className='patient_profile_table_mobile_priscription_table'>
                                        <div className='doctor_patient_profile_add_prescription_mobile_prescription_division'>
                                            <div>
                                                <div className='patient_profile_priscription_mobile_table_name_title'> {prescription.name}</div>
                                                <div className='patient_profile_priscription_mobile_table_date'> {prescription.date}  </div>
                                            </div>
                                            <div>
                                                <div> {prescription.attachment} </div>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                            </div> : null}


                        {ActiveUserTab[2] ?
                            <div className='patient_profile_table_medicalrecords_table_main'>
                                <div className='dashboard_table_main_title'>offers</div>
                                <div className='patient_profile_medicalrecord_button_div'>
                                    <button type="button" className=" patient_profile_medical_records_add_records_button btn" onClick={() => {
                                        setAddMedicalRecord(true)
                                    }}>Add Offers</button>
                                    {/* This css is in Patient Medical record */}
                                </div>
                                {medicalrecordsdata.map((medicalrecords) => (
                                    <div className='patient_profile_medicalrecords_table_main_division'>
                                        <div className='doctor_patient_profile_medicalrec_first_div'>
                                            <div>
                                                <div className='doctor_patient_profile_medicalrec_id'>#{medicalrecords.recordid}</div>
                                                <div className='doctor_patient_profile_small_title'>TITLE NAME</div>
                                                <div className=''>{medicalrecords.title}</div>
                                            </div>
                                        </div>
                                        <div className='doc_pat_pro_medical_sec_div'>
                                            <div className='doctor_patient_profile_small_title'>DATE</div>
                                            <div className=''>{medicalrecords.date}</div>
                                        </div>
                                        <div className='doc_pat_pro_medical_sec_div'>
                                            <div className='doctor_patient_profile_small_title'>SALOON NAME</div>
                                            <div className=''>{medicalrecords.hospitalname}</div>
                                        </div>
                                        <div className='doc_pat_pro_medical_sec_div'>
                                            <div className='doctor_patient_profile_small_title'>PURPOSE</div>
                                            <div className=''>  {medicalrecords.Symptoms}</div>
                                        </div>
                                        <div className='doc_pat_pro_medical_sec_div'>
                                            <div className='doctor_patient_profile_small_title'>ATTACHMENT</div>
                                            <div>{medicalrecords.Attachment}</div>
                                        </div>
                                        <div className='doc_pat_pro_medical_sec_div'>
                                            <div className='doctor_patient_profile_small_title'>CREATED</div>
                                            <div>{medicalrecords.Created}</div>
                                        </div>
                                    </div>
                                ))}

                            </div> : null}

                        {ActiveUserTab[3] ?
                            <div className='patient_profile_billing_table_main'>
                                <div className='dashboard_table_main_title'>Billings</div>
                                {billingsdata.map((billings) => (
                                    <div className='patient_profile_mobile_billing_main_division'>
                                        <div>
                                            <div className='doctor_patient_profile_small_title'>Amount</div>
                                            <div className='docotr_patient_profile_billing_amt'> {billings.amount}</div>
                                            <div className='d-flex'>
                                                <div className='doctor_patient_profile_billings_invoice_no'>#{billings.invoiceno}</div>
                                                <span className='doctor_patient_profile_mobile_billing_slash'>|</span>
                                                <div className='doctor_patient_profile_mobile_paidon'> {billings.paidon} </div>
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className='doctor_patient_profile_table_view me-2'>{billings.print}</div>
                                            <div className='doctor_patient_profile_table_view'>{billings.view}</div>
                                        </div>
                                    </div>
                                ))}


                            </div> : null}




                        {ActiveUserTab[4] ?
                            <div className='doctor_patient_profile_sidebar_division_mobile'>
                                <div className='doctor_patient_profile_sidebar_main_sub_division'>
                                    <div className='doctor_patient_profile_sidebar_img_profile_main_division'><div className='doctor_patient_profile_sidebar_img_profile_division'><img className='doctor_patient_profile_sidebar_img_profile' src={state['Img']}></img></div></div>
                                    <div className='doctor_patient_profile_sidebar_profile_person_name'>{state['name']}</div>
                                    <div className='doctor_patient_profile_sidebar_patient_id_division'>
                                        <div className='doctor_patient_profile_sidebar_patient_id_name'>Customer ID:</div>
                                        <div className='doctor_patient_profile_sidebar_patient_id_value'>{state['displayId']}</div>
                                    </div>
                                    <p className='doctor_patient_profile_sidebar_location_division'><i class="doctor_patient_profile_sidebar_birthdate_icon_cake_location fa-solid fa-location-dot"></i> {state['Address']}</p>
                                </div>

                                <div className='docter_patient_profile_deetails_division'>
                                    <div className='docter_patient_profile_deetails_title_name_division'>
                                        <p className='doctor_patient_profile_user_mo_number_title'>Phone</p>
                                        <p className='doctor_patient_profile_user_mo_number'>{state['Phone']}</p>

                                    </div>
                                    <div className='docter_patient_profile_deetails_title_name_division'>
                                        <p className='doctor_patient_profile_user_mo_number_title'>Age</p>
                                        <p className='doctor_patient_profile_user_mo_number'>{state['Age_Gender']}</p>
                                    </div>

                                    <div className='docter_patient_profile_deetails_title_name_division'>
                                        <div className='doctor_patient_profile_user_mo_number_title'>Blood Group</div>
                                        <div className='doctor_patient_profile_user_mo_number'>{state['Bloodgroup']}</div>
                                    </div>
                                </div>
                            </div> : null}


                    </div>

                </div>

                <div className='doctor_patient_profile_first_section'>
                    <button type="button" className={ActiveUserTab[0] ? 'btn patient_profile_btn_active ' : ' btn patient_profile_btn'} onClick={() => { SetActiveUserTab([true, false, false, false, false]) }}><i class="fa-solid fa-calendar-days fa-xl"></i></button>
                    <button type="button" className={ActiveUserTab[1] ? 'btn patient_profile_btn_active ' : ' btn patient_dashboard_btn'} onClick={() => SetActiveUserTab([false, true, false, false, false])} ><i class="fa-solid fa-file-prescription fa-xl"></i></button>
                    <button type="button" className={ActiveUserTab[2] ? 'btn patient_profile_btn_active ' : ' btn patient_profile_btn'} onClick={() => SetActiveUserTab([false, false, true, false, false])}><i class="fa-solid fa-book-medical fa-xl"></i></button>
                    <button type="button" className={ActiveUserTab[3] ? 'btn patient_profile_btn_active' : ' btn patient_profile_btn'} onClick={() => SetActiveUserTab([false, false, false, true, false])} ><i class="fa-solid fa-file-invoice fa-xl"></i></button>
                    <button type="button" className={ActiveUserTab[4] ? 'btn patient_profile_btn_active' : ' btn patient_profile_btn'} onClick={() => SetActiveUserTab([false, false, false, false, true])}><i class="fa-solid fa-user fa-xl " ></i></button>
                </div>
                <GoToTop />
            </div>
        </>
    )
}
export default DoctorPatientProfile;
