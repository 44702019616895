import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import './Saloonsidebarresponsive.css';
import { useNavigate } from 'react-router-dom';
import patientdashboardimg from '../../media/image.jpg';
import '../../App.css';

export default function Doctorsidebarresponsive() {
    {
        let navigate = useNavigate();
        const [open, setOpen] = useState(false);

        const showDrawer = () => {
            setOpen(true);
        };

        const onClose = () => {
            setOpen(false);
        };

        return (
            <div>
                <div className='doctor_responsive_drawer_main_div'>

                    <i class="fa-solid fa-bars fa-lg" onClick={showDrawer}></i>

                    <Drawer placement="left" onClose={onClose} open={open} width={280}>
                        <div className='Doctorsidebar_main_division'>
                            <div className='Doctorsidebar_division'>
                                <div className='Doctorsidebar_img_profile_main_division'>
                                    <div className='Doctorsidebar_img_profile_division'>
                                        <img className='Doctorsidebar_img_profile' src={patientdashboardimg}></img></div>
                                </div>
                                <div className='Doctorsidebar_profile_person_name'>Dr XYZ</div>
                                {/* <div className='Doctorsidebar_birthdate_division'><i class="Doctorsidebar_birthdate_icon_cake_location fa-solid fa-cake-candles"></i> 24 Jul 1983, 38 years</div> */}
                                <div className='Doctorsidebar_location_division'><i class="Doctorsidebar_birthdate_icon_cake_location fa-solid fa-location-dot"></i> Newyork, USA</div>
                            </div>
                            <div className={window.location.pathname.includes('/admin/dashboard') ? 'Doctorsidebar_dashboard_title_division_active' : "Doctorsidebar_dashboard_title_division"}
                                onClick={() => {

                                    navigate('/admin/dashboard')
                                }}
                            ><i class="fa-solid fa-table-columns me-2"></i> Dashboard</div>
                            <div className={window.location.pathname.includes('/admin/mycustomer') ? 'Doctorsidebar_dashboard_title_division_active' : 'Doctorsidebar_dashboard_title_division'}
                                onClick={() => {

                                    navigate('/admin/mycustomer')

                                }}
                            ><i class="fa-solid fa-file-invoice-dollar me-2"></i> My Customer</div>
                            <div className={window.location.pathname.includes('/admin/schedule-timing') ? 'Doctorsidebar_dashboard_title_division_active' : 'Doctorsidebar_dashboard_title_division'}
                                onClick={() => {
                                    navigate('/admin/schedule-timing')

                                }}
                            ><i class="fa-solid fa-file-invoice-dollar me-2"></i>Schedule Timing</div>
                            {/* <div className='Doctorsidebar_dashboard_title_division'><i class="fa-solid fa-file-medical"></i> Medical Details</div> */}
                            {/* <div className={window.location.pathname.includes('/admin/customerprofile/:PID') ? 'Doctorsidebar_dashboard_title_division_active' : 'Doctorsidebar_dashboard_title_division'}
                                onClick={() => {
                                    navigate('/admin/customerprofile/:PID')

                                }}
                            ><i class="fa-solid fa-gears me-2"></i> Profile Settings</div> */}
                            <div className={window.location.pathname.includes('/admin/adminchangepassword') ? 'Doctorsidebar_dashboard_title_division_active' : 'Doctorsidebar_dashboard_title_division'}
                                onClick={() => {
                                    navigate('/admin/adminchangepassword')

                                }}
                            ><i class="fa-solid fa-lock me-2"></i> Change Password </div>
                            <div className='Doctorsidebar_dashboard_title_division'
                                onClick={() => {
                                    localStorage.clear()
                                    navigate('/')

                                }}
                            ><i class="fa-solid fa-right-from-bracket me-2"></i> Logout </div>

                        </div>
                    </Drawer>
                </div>
            </div>
        )
    }
}
