import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { useStateValue } from '../StateProviders';
import './Customersidebarresponsive.css';
import { useNavigate } from 'react-router-dom';
import patientdashboardimg from '../../media/image.jpg';
import '../../App.css';
export default function () {
    {
        let navigate = useNavigate();
        const [open, setOpen] = useState(false);

        const showDrawer = () => {
            setOpen(true);
        };

        const onClose = () => {
            setOpen(false);
        };

        const [{ isLogin }, dispatch] = useStateValue()


        return (

            <div className='drawer sidebar_responsive_drawer'>
                <div>
                    <i class="fa-solid fa-bars fa-xl" onClick={showDrawer}></i>
                </div>

                <Drawer placement="left" onClose={onClose} open={open} width={280}>
                    <div className='responsive_sidebar_main_division'>
                        <div className='responsive_sidebar_division'>
                            <div className='responsive_sidebar_img_profile_main_division'>
                                <div className='responsive_sidebar_img_profile_division'>
                                    <img className='Doctorsidebar_img_profile' src={patientdashboardimg}></img>
                                </div>
                            </div>
                            <div className='responsive_sidebar_profile_person_name'>Richard Wilson</div>
                            <div className='responsive_sidebar_birthdate_division'><i class="sidebar_birthdate_icon_cake_location fa-solid fa-cake-candles"></i> 24 Jul 1983, 38 years</div>
                            <div className='responsive_sidebar_location_division'><i class="sidebar_birthdate_icon_cake_location fa-solid fa-location-dot"></i> Newyork, USA</div>
                        </div>
                        <div className={window.location.pathname.includes('/dashboard') ? 'responsive_sidebar_dashboard_title_division_active' : "responsive_sidebar_dashboard_title_division"}
                            onClick={() => {

                                navigate('/dashboard')
                            }}><i class="fa-solid fa-table-columns me-2"></i> Dashboard</div>
                        <div className={window.location.pathname.includes('/booking') ? 'responsive_sidebar_dashboard_title_division_active' : "responsive_sidebar_dashboard_title_division"}
                            onClick={() => {

                                navigate('/booking')
                            }}><i class="fa-solid fa-file-invoice-dollar me-2"></i> Book Appointment</div>

                        <div className={window.location.pathname.includes('/profilesetting') ? 'responsive_sidebar_dashboard_title_division_active' : "responsive_sidebar_dashboard_title_division"}
                            onClick={() => {

                                navigate('/profilesetting')
                            }}><i class="fa-solid fa-gears me-2"></i> Profile Settings</div>
                        <div className={window.location.pathname.includes('/changepassword') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
                            onClick={() => {

                                navigate('/changepassword')
                            }}
                        ><i class="fa-solid fa-lock me-2"></i> Change Password </div>

                        <div className='sidebar_dashboard_title_division'
                            onClick={() => {
                                localStorage.clear()
                                dispatch({
                                    type: "Logout"
                                })
                                navigate("")
                            }}
                        ><i class="fa-solid fa-right-from-bracket me-2"></i> Logout </div>
                    </div>
                </Drawer>
            </div>

        )
    }
}
