import './AboutUs.css'
import '../../App.css';
import divider from '../../media/divider.png'
import { useState } from 'react'
import GoToTop from '../GoToTop';

function AboutUs() {
    const [AboutNs, setAboutNs] = useState([true, false, false, false, false])
    return (
        <>
            <div className='aboutpage_main_division'>
                <div className='aboutpage_division container'>
                    {/* aboutus-leftside-start  */}
                    <div className='aboutpage_leftside_division'>
                        <p className='aboutpage_main_title'>
                            about ns salon
                        </p>
                        <p className='aboutpage_subtitle'>the ns salon difference</p>
                        <img className='aboutpage_divider' src={divider} />
                        <div className='aboutpage_title'>
                            Versa Salon is an Aveda Concept Salon located in Uptown Charlotte. All of our valued customers receive free parking. Our mission is to provide the highest quality service, in union with the highest quality products for the well being of our guests and the environment.
                        </div>
                        <div className='aboutpage_wheweexixt_title'>
                            WHY WE EXIST
                        </div>
                        <div className='aboutpage_wheweexixt_subtitle'>
                            Versa Salon is an inclusive beauty company focusing on the unique needs and desires of our guests, making us the most versatile salon: providing professional service, experience and results above and beyond expectations!
                        </div>
                    </div>
                    {/* aboutus-leftside-end  */}

                    {/* aboutus-rightside-start  */}
                    <div className='aboutpage_rightside_main_division'>
                        <div className='aboutpage_rightside_division'>
                            <div>
                                <div className={AboutNs[0] ? 'aboutus_rightside_active_tab' : 'aboutus_rightside_tab'}
                                    onClick={() => {
                                        setAboutNs([true, false, false, false, false])
                                    }}>
                                    special offers</div>
                                <div className={AboutNs[1] ? 'aboutus_rightside_active_tab' : 'aboutus_rightside_tab'}
                                    onClick={() => { setAboutNs([false, true, false, false, false]) }}>
                                    review</div>
                                <div className={AboutNs[2] ? 'aboutus_rightside_active_tab' : 'aboutus_rightside_tab'}
                                    onClick={() => { setAboutNs([false, false, true, false, false]) }}>
                                    special feed</div>
                                <div className={AboutNs[3] ? 'aboutus_rightside_active_tab' : 'aboutus_rightside_tab'}
                                    onClick={() => { setAboutNs([false, false, false, true, false]) }}>
                                    blog</div>
                                <div className={AboutNs[4] ? 'aboutus_rightside_active_tab' : 'aboutus_rightside_tab'}
                                    onClick={() => { setAboutNs([false, false, false, false, true]) }}>
                                    contact</div>

                            </div>
                        </div>
                        <div className='aboutus_right_side_1img_section'>
                            <div className='aboutus_right_side_images_main_division'>guest offer</div>
                        </div>
                        <div className='aboutus_right_side_2img_section'>
                            <div className='aboutus_right_side_images_main_division'>refer</div>
                        </div>
                        <div className='aboutus_right_side_3img_section'>
                            <div className='aboutus_right_side_images_main_division'>&gift cards</div>
                        </div>
                    </div>
                    {/* aboutus-rightside-end  */}
                </div>
                <GoToTop/>
            </div>
        </>
    )
}
export default AboutUs;