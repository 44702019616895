import React from 'react';
import './Homepage.css';
import '../../App.css';
import brandlogo from '../../media/brandlogo.png';
import b1 from '../../media/b1.jpg';
import b2 from '../../media/b2.jpg';
import b3 from '../../media/b3.jpg';
import b4 from '../../media/b4.jpg';
import h1 from '../../media/h1.jpg';
import h2 from '../../media/h2.jpg';
import h3 from '../../media/h3.jpg';
import h4 from '../../media/h4.jpg';
import curveline from '../../media/curveline.png'
import facewash from '../../media/facewash.jpg'
import babu from '../../media/babu.jpg'
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { useNavigate } from 'react-router-dom';
import GoToTop from '../GoToTop';
function HomePage() {
    let navigate = useNavigate();

    return (
        <div>
            <div className='beauty_header'>

            </div>
            <div className='ns_firstpage'>

                <div className='ns_firstpage_details_div'>
                    <img className='brandlogo_div' src={brandlogo}></img>
                    <h1 className='ns_firstpage_title_beauty'>NYSHA BEAUTE</h1>
                    <h1 className='ns_firstpage_title_just_for'>JUST FOR YOU</h1>
                </div>
            </div>
            <div class='image_hover_section_main_div row'>

                <div class='secondpage_hover_tags col-12 col-lg-3 col-md-6 col-sm-6 '>
                    <div class='beauty_center_div'>
                        <div>
                            <img class='original_iamge' src={b1}></img>
                            <img class='hover_image' src={h1}></img>
                        </div>
                    </div>
                    <div class='details_section'>
                        <p class='details_section_p'>Mauris efficitur purus <br /> id neque tempor, eu <br />faucibus ligula sodales.</p>
                    </div>
                </div>

                <div class='secondpage_hover_tags col-12 col-lg-3 col-md-6 col-sm-6 '>
                    <div class='beauty_center_div'>
                        <div>
                            <img class='original_iamge' src={b2}></img>
                            <img class='hover_image' src={h2}></img>
                        </div>
                    </div>
                    <div class='details_section'>
                        <p class='details_section_p'>Mauris efficitur purus  <br /> id neque tempor, eu  <br /> faucibus ligula sodales.</p>
                    </div>
                </div>

                <div class='secondpage_hover_tags col-12 col-lg-3 col-md-6 col-sm-6 '>
                    <div class='beauty_center_div'>
                        <div>
                            <img class='original_iamge' src={b3}></img>
                            <img class='hover_image' src={h3}></img>
                        </div>
                    </div>
                    <div class='details_section'>
                        <p class='details_section_p'>Mauris efficitur purus  <br /> id neque tempor, eu   <br /> faucibus ligula sodales.</p>
                    </div>
                </div>

                <div class='secondpage_hover_tags col-12 col-lg-3 col-md-6 col-sm-6 '>
                    <div class='beauty_center_div'>
                        <div>
                            <img class='original_iamge' src={b4}></img>
                            <img class='hover_image' src={h4}></img>
                        </div>
                    </div>
                    <div class='details_section'>
                        <p class='details_section_p'>Mauris efficitur purus  <br /> id neque tempor, eu  <br /> faucibus ligula sodales.</p>
                    </div>
                </div>

            </div>




            <div className='ns_thirdpage_div'>
                <div className='thirdpage_second_div'>
                    <p className='thirdpage_second_div_main_title'>ONE OF THE BEST BEAUTY SOLUTION NEAR YOU</p>
                    <p className='thirdpage_second_div_para'>Our mission is to provide the highest quality service, in union with the highest quality products for the well being of our guests and the environment.</p>
                    <button type="button" class="btn btn book_now_btn" onClick={() => { navigate('booking') }}>BOOK NOW</button>
                </div>
            </div>
            <div className='ns_fourthpage_div'>
                <div className='ns_fourthpage_first_div'>
                    <p className='few_quates'>Few Quates</p>
                    <h6 className='from_our'>From our clients</h6>
                </div>
                <div className='ns_fourthpage_first_div_swiper'>
                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                        <SwiperSlide>
                            <div>
                                <img className='user_image' src={babu}></img>
                                <p className='user_title'>
                                    A paragraph is a series of sentences that are organized and coherent, <br />and are all related to a single topic. Almost every piece of writing you do that is longer than a few sentences should be organized into paragraphs.
                                </p>
                                <p className='user_title_name'>patel Patel</p>
                                <p className='user_title_number'>99</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <img className='user_image' src={babu}></img>
                                <p className='user_title'>
                                    A paragraph is a series of sentences that are organized and coherent,<br /> and are all related to a single topic. Almost every piece of writing you do that is loqwfqfasfa asfaf wqrwdf  qwdfqwdq  nger than a few sentences should be organized into paragraphs.
                                </p>
                                <p className='user_title_name'>Ramesh Patel</p>
                                <p className='user_title_number'>98</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide> <div>
                            <img className='user_image' src={babu}></img>
                            <p className='user_title'>
                                A paragraph is a series<br /> sentences that are organized and coherent, and are all related to a single topic. Almost break.
                            </p>
                            <p className='user_title_name'>Suresh Patel</p>
                            <p className='user_title_number'>80</p>
                        </div></SwiperSlide>
                    </Swiper>
                </div>
            </div >



            {/* <div className='ns_fifthpage_div'>
                <div className=' ns_fifthpage_div_section_one '>
                    <h1 className='ns_fifthpage_div_section_one_title'>MEET</h1>
                    <h1 className='ns_fifthpage_div_section_one_title'>YOUR</h1>
                    <h1 className='ns_fifthpage_div_section_one_title'>NEEDS</h1>
                    <img src={curveline}></img>
                    <p className='ns_fifthpage_div_section_one_paragraph'>You can order our products at<br />
                        home and we will be  happy to <br />
                        deliver them to you</p>
                    <button type="button" class="btn btn shop_now_btn">BOOK NOW</button>
                </div>
                <div className=''>
                    <img src={facewash} ></img>
                </div>
            </div> */}

            {/* <div className='ns_sixthpage_div'>
                <div class="mapouter">
                    <div class="gmap_canvas">
                        <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=1440&amp;height=686&amp;hl=en&amp;q=nysha sol&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    </div>
                </div>
                <div className='ns_sixthpage_second_div' >
                    <div className='container '>
                        <div className='container_first_div col-md-6'>
                            <h1 className='container_first_div_title'>Feel Free To</h1>
                            <h1 className='container_first_div_title'>Contact us</h1>
                            <p className='container_first_div_paragraph'>or fill our contact form online</p>
                            <div className='postal_add_div'>
                                <h6 className='postal_add_title'>Postal Address : </h6>
                                <h6 className='postal_add_title'>ff-9 nysha solutions </h6>
                            </div>
                            <div className='salon_add_div'>
                                <h6 className='salon_add_title'>Salon Address : </h6>
                                <h6 className='salon_add_title'>45 patel complex </h6>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <input class="form-control form-control-lg mb-3 container_input_tag" type="text" placeholder="First Name" aria-label=".form-control-lg example" />
                            <input class="form-control form-control-lg mb-3 container_input_tag" type="text" placeholder="Last Name" aria-label=".form-control-lg example" />
                            <input class="form-control form-control-lg mb-3 container_input_tag" type="text" placeholder="E-mail address" aria-label=".form-control-lg example" />

                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            <button type="button" class="btn btn mt-3 send_btn">SEND</button>
                        </div>
                    </div>
                </div>
            </div> */}
            <GoToTop />
        </div>

    )
}
export default HomePage;
