import './CustomerForgotPassword.css';
import doccureforgotpassworfimg from '../../media/login_img.jpg';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import GoToTop from '../GoToTop';

function DoccureForgotPassword() {

    let navigate = useNavigate()

    const [OTPbutton , setOTPbutton] = useState(false)
    const [userOTP,setuserOTP] = useState("-1")
    const [sysOTP,setsysOTP] = useState("-2")
    const [Email,setEmail] = useState("")
    const [Password,setPassword] = useState('')
    return (
        <div className='container'>
            <div className='doccure_forgot_password_main_division'>
                <div className='doccure_forgot_password_main_first_division'>
                    <div className='d-flex justify-content-center'><div className='doccure_forgot_password_img_division'>
                        <img className='doccure_forgot_password_img' src={doccureforgotpassworfimg} />
                    </div></div>
                </div>

                <div className='doccure_forgot_password_main_second_division'>
                    <div className='doccure_forgot_password_title_name'>Forgot Password?</div>
                    <div className='doccure_forgot_password_sub_title_name'>Enter your email to get a password reset link</div>

                    <div class="doccure_forgot_password_email_field_division form-floating mt-4">
                        <input type="email" class="doccure_forgot_password_email_field form-control" id="floatingInput" placeholder="name@example.com" 
                                    onChange={(e)=>{
                                        setEmail(e.target.value) 
                                        setOTPbutton(false)
                                        }}
                                        onKeyDown={(e)=>{
                                            if(e.key === 'Enter')
                                            {

                                                if(Email.length!==0)
                                                {
                                                    setOTPbutton(true)
                        
                                                var Url = window.API_PREFIX + 'patient/generate_otp_forget_password'
                                                fetch(Url, {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify(
                                                        {
                                                  
                                                            Email:Email,
                                       
                                                        }
                                                    ),
                                                }).then(resp => resp.json()).then(data => {
                                                    console.log(data)
                                                    if (data['status'] === "1") {
                        
                                                        setsysOTP(data['OTP'])
                                                    }
                                                    else if
                                                    (data['status'] === "-1") {
                        
                                                       alert('Email not found')
                                                       setOTPbutton(false)
                                                    } 
                                                
                                                })
                        
                                                }
                                            }
                                        }}
                        />
                        <label for="floatingInput">Email</label>
                    </div>
                    <button className={Email.length!==0 && !OTPbutton?"doccure_forgot_password_btn_login_division":'doccure_forgot_password_btn_login_division_inactive'} disabled={!(Email.length!==0 && !OTPbutton)}
                     onClick={()=>{
                        if(Email.length!==0)
                        {
                            setOTPbutton(true)

                        var Url = window.API_PREFIX + 'patient/generate_otp_forget_password'
                        fetch(Url, {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json',
                            },
                            body: JSON.stringify(
                                {
                          
                                    Email:Email,
               
                                }
                            ),
                        }).then(resp => resp.json()).then(data => {
                            console.log(data)
                            if (data['status'] === "1") {

                                setsysOTP(data['OTP'])
                            }
                            else if
                            (data['status'] === "-1") {

                               alert('Email not found')
                               setOTPbutton(false)
                            } 
                        
                        })

                        }
                        
                    }}
                    
                    >Send OTP on email</button>
                  
                   {OTPbutton?<> <div class="doccure_forgot_password_email_field_division form-floating mt-4">
                        <input type="otp" class="doccure_forgot_password_email_field form-control" id="floatingInput" placeholder="OTP" 
                              onChange={(e)=>{
                                setuserOTP(e.target.value) 
                        
                                }}
                        />
                        <label for="floatingInput">OTP</label>
                    </div>


                {userOTP===sysOTP?  <div class="doccure_forgot_password_email_field_division form-floating mt-4">
                        <input type="password" class="doccure_forgot_password_email_field form-control" id="floatingInput" placeholder="Password" 
                              onChange={(e)=>{
                                setPassword(e.target.value) 
                          
                                }}
                            onKeyDown={(e)=>{
                                if(e.key === 'Enter')
                                {

                                    if(userOTP===sysOTP){

                                        var Url = window.API_PREFIX + 'patient/forget_password'
                                        fetch(Url, {
                                            method: 'POST',
                                            headers: {
                                                'Content-type': 'application/json',
                                            },
                                            body: JSON.stringify(
                                                {
                                          
                                                    Email:Email,
                                                    Password:Password
                               
                                                }
                                            ),
                                        }).then(resp => resp.json()).then(data => {
                                            console.log(data)
                                            if (data['status'] === "1") {
                                                alert('New password set successfully')
                                                navigate("/login")
                
                                            }
                                            else if
                                            (data['status'] === "-1") {
                
                                               alert('New password failed')
                             
                                            } 
                                        
                                        })
                                    }
                                    else{
                                        alert("OTP incorrect")
                                    }
                                }
                            }}

                     
                        
                        />
                        <label for="floatingInput">New Password</label>
                    </div>:null}
                    
                    </>:null}

                    <div className='doccure_forgot_password_forgot_password_division'
                    onClick={()=>
                    {
                            navigate('/login')
                    }}
                    >Remember your password?</div>
                    <button className={Password.length!==0 && userOTP===sysOTP?"doccure_forgot_password_btn_login_division":'doccure_forgot_password_btn_login_division_inactive'} disabled={!(Password.length!==0)}
                     onClick={()=>{
                    
              if(userOTP===sysOTP){

                        var Url = window.API_PREFIX + 'patient/forget_password'
                        fetch(Url, {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json',
                            },
                            body: JSON.stringify(
                                {
                          
                                    Email:Email,
                                    Password:Password
               
                                }
                            ),
                        }).then(resp => resp.json()).then(data => {
                            console.log(data)
                            if (data['status'] === "1") {
                                alert('New password set successfully')
                                navigate("/login")

                            }
                            else if
                            (data['status'] === "-1") {

                               alert('New password failed')
             
                            } 
                        
                        })
                    }
                    else{
                        alert("OTP incorrect")
                    }
                        
                        
                    }}
                    >Reset Password</button>
                </div>
            </div>
            <GoToTop/>
        </div>
    )
}
export default DoccureForgotPassword;