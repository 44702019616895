import logo from './logo.svg';
import 'antd/dist/antd.min.css';
import { useStateValue } from './Component/StateProviders';
// import './App.css';
import { Component } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom"


import Schedule_Timing from './Component/Saloon/Schedule_Timings';
import Patient_Booking from './Component/Customer/Customer_Booking';
import Doctor_Dashboard from './Component/Saloon/Saloon_Dashboard';
import OrderSummary from './Component/Customer/OrderSummary';
import PatientMedicalRecords from './Component/Customer/CustomerMedicalRecords';
import DoctorPatientProfile from './Component/Saloon/SaloonPatientProfile';
import PatientDashboard from './Component/Customer/CustomerDashboard';
import DoctorMyPatient from './Component/Saloon/SaloonMyPatient';
import Adminlayout from './Component/Saloon/Adminlayout'
import HomeLayout from './Component/HomeLayout'
import DoccureLogin from './Component/Customer/CustomerLogin'
import DoccureRegister from './Component/Customer/CustomerRegister'
import DoccureForgotPassword from './Component/Customer/CustomerForgotPassword'
import PatientProfilesetting from './Component/Customer/CustomerProfilesettings'
import PatientProfilesettings from './Component/Customer/CustomerProfilesettings';
import PatientChangePassword from './Component/Customer/CustomerChangePassword';
import DoctorChangePassword from './Component/Saloon/SaloonChangePassword';
import AddPrescription from './Component/Saloon/AddPrescription';
import PatientRegister from './Component/Customer/PatientRegister';
import DoctorSidebar from './Component/Saloon/SaloonSidebar';
import ContactUs from './Component/ContactUs/ContactUs';
import AboutUs from './Component/AboutUs/AboutUs';
import Homepage from './Component/Homepage/Homepage';
import Appointmentbookingsuccessfullypopup from './Component/Customer/Appointmentbookingsuccessfullypopup';
import TermsAndConditions from './Component/TermsAndConditions';
import PrivacyAndPolicy from './Component/PrivacyAndPolicy';
import Footer from '../src/Component/Footer/Footer';
import Header from './Component/Header/Header';
// import Profilesettings from './Component/Profilesettings;';
window.API_PREFIX = 'https://api.nsdoc.in/'
// window.API_PREFIX = 'http://localhost:8000/'

function App() {
  let navigate = useNavigate()
  const [{ IsRegister }, dispatch] = useStateValue();
  return (
    <>
      <Routes path="">

        <Route path="" element={<HomeLayout />} >
          <Route path="login" element={<DoccureLogin />} />
          <Route path="booking" element={<Patient_Booking />} />
          {/* <Route  path="medicalrecord" element={<PatientMedicalRecords/>} /> */}
          <Route path="dashboard" element={<PatientDashboard />} />
          <Route path="changepassword" element={<PatientChangePassword />} />
          <Route path='profilesetting' element={<PatientProfilesettings />} />
          <Route path='ordersummary' element={<OrderSummary />} />
          {/* <Route  path='registerdetail' element={<PatientRegister/>} /> */}
          <Route path='patientregister' element={<PatientRegister />} />
          <Route path='contactUs' element={<ContactUs />} />
          <Route path='aboutus' element={<AboutUs />} />
          <Route path='' element={<Homepage />} />
          <Route path='bookingsuccessfully' element={<Appointmentbookingsuccessfullypopup />} />
        
        </Route>

        <Route path="/forgetpassword" element={<><Header /><DoccureForgotPassword /><Footer /></>} />
        <Route path="/registerdetail" element={<><Header />{!IsRegister ? <PatientRegister /> : null}<Footer /></>} />
        <Route path="/register" element={<><Header /><DoccureRegister /><Footer /></>} />
        <Route path='PrivacyAndPolicy' element={<><Header /><PrivacyAndPolicy /><Footer /></>}/>
          <Route path='TermsAndConditions' element={<><Header /><TermsAndConditions /><Footer /></>} />
     

        <Route path="/admin" element={<Adminlayout />} >
          <Route path="schedule-timing" element={<Schedule_Timing />} />
          <Route path="dashboard" element={<Doctor_Dashboard />} />
          <Route path="mycustomer" element={<DoctorMyPatient />} />
          <Route path="customerprofile/:PID" element={<DoctorPatientProfile />} />
          <Route path="adminchangepassword" element={<DoctorChangePassword />} />
          <Route path="addprescription" element={<AddPrescription />} />
          <Route path="doctorsidebar" element={<DoctorSidebar />} />
       
        </Route>

      </Routes>
    </>
  );
}

export default App;
