import * as React from "react";
import { useState } from "react";
import Popup from "reactjs-popup";
import "./Appointmentbookingsuccessfullypopup.css";
import '../../App.css';

export default function BasicModal() {
  const [booking, setbooking] = useState(false);
  const [bookingrejected, setrejectedbooking] = useState(false);

  return (
    <div>
      <div
        className="patient_popup_main_Section"
        onClick={() => {
          setbooking(true);
        }}
      >
        <button type="button" className="btn">
          Booking Popup
        </button>
      </div>
      <div
        className="patient_popup_main_Section"
        onClick={() => {
          setrejectedbooking(true);
        }}
      >
        <button type="button" className="btn">
          Booking Rejected Popup
        </button>
      </div>

      <Popup
        modal
        open={booking}
        onClose={() => {
          setbooking(false);
        }}
      >
        <div className="Popup_main_division">
          <div>
            <i
              onClick={() => {
                setbooking(false);
              }}
            ></i>

            <div className="popup_circle_main_div">
              <div className="popup_circle">
                <i class="fa-solid fa-check fa-5x popup_circle_right"></i>
              </div>
              {/* <div className="popup_circle_rejected">
                <i class="fa-solid fa-xmark fa-5x popup_circle_rejected_icon"></i>
              </div> */}
            </div>
            {/* <div className="popup_circle_main_div">
              <div className="popup_circle_rejected">
                <i class="fa-solid fa-xmark fa-5x popup_circle_rejected_icon"></i>
              </div>
            </div> */}

            <div className="popup_text">
              <h3>Appointment Booked Successfully</h3>
              <p>
                Appointment booked with <b>Dr. Darren Elder</b>
                <br />
                on 29 Dec 2022 5:00PM to 6:00PM.
              </p>
            </div>
            <div className="popup_button">
              <button type="submit" className="popup_button_dashboard">
                Go To Dashboard
              </button>
            </div>
          </div>
        </div>
      </Popup>

      <Popup
        modal
        open={bookingrejected}
        onClose={() => {
          setrejectedbooking(false);
        }}
      >
        <div className="Popup_main_division_rejected">
          <div>
            <i
              onClick={() => {
                setrejectedbooking(false);
              }}
            ></i>

            <div className="popup_circle_main_div">
              {/* <div className="popup_circle">
                <i class="fa-solid fa-check fa-5x popup_circle_right"></i>
              </div> */}
              <div className="popup_circle_rejected">
                <i class="fa-solid fa-xmark fa-5x popup_circle_rejected_icon"></i>
              </div>
            </div>
            {/* <div className="popup_circle_main_div">
              <div className="popup_circle_rejected">
                <i class="fa-solid fa-xmark fa-5x popup_circle_rejected_icon"></i>
              </div>
            </div> */}

            <div className="popup_text">
              <h3>Appointment Rejected</h3>
              <p>
                Appointment Rejected with <b>Dr. Darren Elder</b>
                <br />
                on 29 Dec 2022 5:00PM to 6:00PM.
              </p>
            </div>
            <div className="popup_button_rejected">
              <button type="submit" className="popup_button_dashboard_rejected">
                Go To Dashboard
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}
