import "./CustomerRegister.css";
import doccureloginimg from "../../media/login_img.jpg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useStateValue } from "../StateProviders";
import '../../App.css';
import GoToTop from '../GoToTop';

function DoccureRegister() {
  let navigate = useNavigate();
  const [{ IsLogin }, dispatch] = useStateValue();

  const [userName, setuserName] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [userOTP, setuserOTP] = useState("-1");
  const [systemOTP, setsystemOTP] = useState("-2");
  const [buttonFlag, setbuttonFlag] = useState(false);
  const [OTPbuttonFlag, setOTPbuttonFlag] = useState(false);

  const [Inputtype, setInputtype] = useState("password");
  const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);
  return (
    <div className="container">
      <div className="doccure_register_main_division">
        <div className="doccure_register_main_first_division">
          <div className="d-flex justify-content-center">
            <div className="doccure_register_img_division">
              <img className="doccure_register_img" src={doccureloginimg} />
            </div>
          </div>
        </div>

        <div className="doccure_register_main_second_division">
          <div className="doccure_register_title_main_division">
            <div className="doccure_register_title_name">Customer Register</div>
            {/* <div className='doccure_register_sub_name_title'>Are you a Doctor?</div> */}
            {/* <div className='doccure_register_sub_name_title'>Not a Doctor?</div> */}
          </div>

          <div class="doccure_register_email_field_division form-floating mt-3">
            <input
              type="text"
              class="doccure_register_email_field form-control"
              id="floatingInput"
              placeholder="name@example.com"
              onChange={(e) => {
                setuserName(e.target.value);
              }}
            />
            <label for="floatingInput">Name</label>
          </div>
          <div class="doccure_register_email_field_division form-floating mt-3">
            <input
              type="email"
              class="doccure_register_email_field form-control"
              id="floatingPassword"
              placeholder="Password"
              onChange={(e) => {
                setEmail(e.target.value);
                setOTPbuttonFlag(false);
              }}
            />
            <label for="floatingPassword">Email</label>
          </div>
          <button
            className={
              Email.length !== 0 && userName.length !== 0 && !OTPbuttonFlag
                ? "doccure_register_btn_login_division_active"
                : "doccure_register_btn_login_division"
            }
            disabled={
              !(Email.length !== 0 && userName.length !== 0 && !OTPbuttonFlag)
            }
            onClick={() => {
              if (Email.length !== 0 && userName.length !== 0) {
                var Url = window.API_PREFIX + "patient/generate_otp";
                fetch(Url, {
                  method: "POST",
                  headers: {
                    "Content-type": "application/json",
                  },
                  body: JSON.stringify({
                    Email: Email,
                  }),
                })
                  .then((resp) => resp.json())
                  .then((data) => {
                    console.log(data);
                    if (data["status"] === "1") {
                      setsystemOTP(data["OTP"]);
                      setOTPbuttonFlag(true);
                    } else if (data["status"] === "0") {
                      alert("Email already register");
                    }
                  });
              }
            }}
          >
            Generate OTP
          </button>
          <div class="doccure_register_email_field_division form-floating mt-3">
            <input
              type="number"
              class="doccure_register_email_field form-control"
              id="floatingPassword"
              placeholder="Password"
              onChange={(e) => {
                setuserOTP(e.target.value);
                if (e.target.value === systemOTP) {
                  setbuttonFlag(true);
                } else {
                  setbuttonFlag(false);
                }
              }}
            />
            <label for="floatingPassword">OTP</label>
          </div>

          <div className="doccure_register_password_division">
            <div class="doccure_register_email_field_division form-floating">
              <input
                type={Inputtype}
                class="doccure_register_email_field1 form-control"
                id="floatingPassword"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <label for="floatingPassword">Create Password</label>
            </div>
            <div className="doccure_register_password_eye">
              <i
                className="eye_icon"
                onClick={(e) => {
                  if (Inputtype == "password") {
                    setInputtype("text");
                    setButtonName(<i class="fa-solid fa-eye-slash"></i>);
                  } else {
                    setInputtype("password");
                    setButtonName(<i class="fa-solid fa-eye"></i>);
                  }

                  e.preventDefault();
                }}
              >
                {ButtonName}
              </i>
            </div>
          </div>
          <div
            className="doccure_register_forgot_password_division"
            onClick={() => {
              navigate("/login");
            }}
          >
            Already have an account ?
          </div>
          <button
            className={
              buttonFlag
                ? "doccure_register_btn_login_division_active"
                : "doccure_register_btn_login_division"
            }
            disabled={!buttonFlag}
            onClick={() => {
              if (
                Email.length !== 0 &&
                userName.length !== 0 &&
                Password.length !== 0 &&
                userOTP === systemOTP
              ) {
                var Url = window.API_PREFIX + "patient/register_user";
                fetch(Url, {
                  method: "POST",
                  headers: {
                    "Content-type": "application/json",
                  },
                  body: JSON.stringify({
                    UserName: userName,
                    Email: Email,
                    Password: Password,
                  }),
                })
                  .then((resp) => resp.json())
                  .then((data) => {
                    console.log(data);
                    if (data["status"] === "1") {
                      localStorage.setItem("DToken", data["Token"]);

                      dispatch({ type: "Login" });

                      navigate("/registerdetail");
                    } else {
                      alert(data["message"]);
                    }
                  });
              }
            }}
          >
            Signup
          </button>

          {/*  <div className='doccure_register_or_division'>or</div>

                    <div className='doccure_register_login_button_division'>
                        <button className='doccure_register_login_button_facebook'><i class="doccure_register_login_btn_facebook fa-brands fa-facebook-f"></i>Login</button>
                        <button className='doccure_register_login_button_google'><i class="doccure_register_login_btn_google fa-brands fa-google"></i>Login</button>
                    </div> */}
        </div>
      </div>
      <GoToTop />
    </div>
  );
}
export default DoccureRegister;
