import './ContactUs.css';
import '../../App.css';
import contactusbgimg from '../../media/new-guest-promo-image.jpg';
import contactusbgimg1 from '../../media/reviews-promo-image.jpg';
import contactusbgimg2 from '../../media/special-offers-promo-image.jpg';
import contactusdivider from '../../media/divider.png';
import GoToTop from '../GoToTop';

function ContactUs() {
    return (
        <>
            <div>
                <div className='contactus_main_disivion'>
                    <div className='contactus_disivion container'>
                        <div className='contactus_first_main_disivion'>
                            <div className='contactus_main_title'>CONTACT US</div>
                            <div className='contactus_sub_title'>Let’s Connect!</div>
                            <img className='contactus_divider' src={contactusdivider} />
                            <div className='contactus_address_field_dvsn'>
                                <a className='contactus_address_field_dvsn' href={'https://goo.gl/maps/YCwQDd9HpKgVPXiW8'} target="_blank" >FF-9, Sun Rise Shops & Flats,<br /> Waghodia Rd, near Vrundavan Crossing,<br /> Vadodara, 390019</a>
                            </div>
                            <div className='contactus_number_field_dvsn'>1234567890</div>
                            <div className='contactus_email_field_dvsn'><u>info@nyshasolutions.com</u></div>
                            <div className='contactus_hours_field_dvsn'>HOURS</div>
                            <div className='contactus_hours_field_main_dvsn'>
                                <div className='contactus_hours_days_field'>
                                    <div>Tuesday – Friday</div>
                                    <div>Saturday</div>
                                    <div>Sunday & Monday</div>
                                </div>
                                <div className='contactus_hours_time_field'>
                                    <div>11:00am – 7:00pm</div>
                                    <div>9:00am – 4:00pm</div>
                                    <div>Closed</div>
                                </div>
                            </div>
                            <div className='contactus_social_main_division'>

                                <a href={"https://twitter.com/nyshasolutions/"} target="_blank">
                                    {" "}
                                    <div className='contactus_social_icon_dvsn'><i class="fa-brands fa-twitter"></i></div>
                                </a>

                                <a href={"https://www.facebook.com/NYSHASOLUTIONS/"} target="_blank">
                                    {" "}
                                    <div className='contactus_social_icon_dvsn'><i class="fa-brands fa-facebook-f"></i></div>
                                </a>
                                <div className='contactus_social_icon_dvsn'><i class="fa-brands fa-google-plus-g"></i></div>
                                <div className='contactus_social_icon_dvsn'><i class="fa-brands fa-pinterest"></i></div>
                                <div className='contactus_social_icon_dvsn'><i class="fa-solid fa-camera"></i></div>
                                <div className='contactus_social_icon_dvsn'><i class="fa-solid fa-wifi"></i></div>
                            </div>
                            <div className='contactus_hours_field_text_section'>We enjoy getting feedback from our clients and potential clients. If you’d like to send us a message, please complete this short form and we’ll be in touch soon! </div>
                            <div className='contactus_text_field_main_division'>
                                <label class="">Your Name:</label>
                                <input type="text" class="contactus_text_field_dvsn form-control" />
                            </div>
                            <div className='contactus_text_field_main_division'>
                                <label class="">Your Email:</label>
                                <input type="text" class="contactus_text_field_dvsn form-control" />
                            </div>
                            <div className='contactus_text_field_main_division'>
                                <label class="">Message:</label>
                                <textarea type="text" class="contactus_text_field_message_dvsn form-control" />
                            </div>
                            <div className='contactus_verify_section_main_division'>
                                <div className='contactus_add_number_text'>93 + 52 =</div>
                                <input type="text" class="contactus_text_field_dvsn form-control" />
                                <button className='contactus_send_btn_division'>Send</button>
                            </div>
                        </div>

                        <div className='contactus_second_main_disivion'>

                            <div className='contactus_right_side_img_section'>
                                <div className='contactus_right_side_img_section_opacity'>
                                    <div className='contactus_right_side_img_text'>$20 NEW</div>
                                    <div className='contactus_right_side_img_text1'>GUEST OFFER</div>
                                </div>
                            </div>

                            <div className='contactus_right_side_img_section1'>
                                <div className='contactus_right_side_img_section_opacity'>
                                    <div className='contactus_right_side_img_text'>REFER</div>
                                    <div className='contactus_right_side_img_text1'>A FRIEND</div>
                                </div>
                            </div>

                            <div className='contactus_right_side_img_section2'>
                                <div className='contactus_right_side_img_section_opacity'>
                                    <div className='contactus_right_side_img_text'>BOOK ONLINE</div>
                                    <div className='contactus_right_side_img_text1'>& GIFT CARDS</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GoToTop />
            </div>
        </>
    )
}
export default ContactUs;