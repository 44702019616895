import './SaloonMyPatient.css'
import doctormypatientimage from '../../media/image.jpg'
import DoctorSidebar from './SaloonSidebar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css'
import Doctorsidebarresponsive from './Saloonsidebarresponsive';
import '../../App.css';
import GoToTop from '../GoToTop';

function DoctorMyPatient() {
    const [patientData, setpatientData] = useState([])
    let navigate = useNavigate()
    useEffect(() => {
        var Url = window.API_PREFIX + 'admin/allpatient'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),


                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {
                setpatientData([...data['allpatient']])
            }
        })
    }, [])
    return (
        <>
            {/* breadcrumbs-start */}
            <div className='doctormypatient_breadcrumb_main_division'>
                <div className='doctormypatient_breadcrumb_division'>
                    <p className='doctormypatient_breadcrumb_title' onClick={() => { navigate('/') }}>home</p>
                    <p className='doctormypatient_breadcrumb_title1'>/</p>
                    <p className='doctormypatient_breadcrumb_title2'>dashboard</p>
                </div>
                <div>
                    <h1 className='doctormypatient_breadcrumb_title_main'>My Customer</h1>
                </div>
            </div>
            {/* breadcrumbs-end */}

            <div className="container-fuild">
                <div className='slidebar_and_component'>
                    <DoctorSidebar />
                    <Doctorsidebarresponsive />
                    {/* <div className='sidebar_main_division'>
                        <div className='sidebar_division'></div>
                    </div> */}
                    <div className='component_division'>
                        <div className='doctormypatient_main_diviison'>


                            {/*  */}
                            {patientData.map((eachPatient) => {
                                return (
                                    <div className='doctormypatient_card_division'
                                        onClick={() => {
                                            navigate("/admin/customerprofile/" + eachPatient['id'],
                                                {
                                                    state: {
                                                        displayId: eachPatient['displayid'],
                                                        name: eachPatient['patientName'],
                                                        Img: eachPatient['Img'],
                                                        Address: eachPatient['Address'],
                                                        Bloodgroup: eachPatient['Bloodgroup'],
                                                        Age_Gender: eachPatient['Age-Gender'],
                                                        Phone: eachPatient['Phone']

                                                    }
                                                })
                                        }}
                                    >
                                        <div className='doctormypatient_card_image_main_division'>
                                            <div className='doctormypatient_card_image_division'>
                                                <img className='doctormypatient_card_image' src={eachPatient['Img']}></img>
                                            </div>
                                        </div>
                                        <p className='doctormypatient_card_name'>{eachPatient['patientName']}</p>
                                        <p className='doctormypatient_card_patientid'>Customer ID : <span>{eachPatient['displayid']}</span> </p>
                                        <div className='doctormypatient_card_icon_address'> <i class="fa-solid fa-location-dot"></i>{eachPatient['Address']}</div>
                                        <div className='doctormypatient_card_patient_details_division'>
                                            <div className='doctormypatient_card_patient_details'>
                                                <div className='doctormypatient_card_patient_details_leftside'>phone</div>
                                                <div className='doctormypatient_card_patient_details_rightside'>{eachPatient['Phone']}</div>
                                            </div>
                                            {/* <div className='doctormypatient_card_patient_details'>
                                        <div className='doctormypatient_card_patient_details_leftside'>age</div>
                                        <div className='doctormypatient_card_patient_details_rightside'>{eachPatient['Age-Gender']}</div>
                                    </div>
                                    <div className='doctormypatient_card_patient_details'>
                                        <div className='doctormypatient_card_patient_details_leftside'>blood group</div>
                                        <div className='doctormypatient_card_patient_details_rightside'>{eachPatient['Bloodgroup']}</div>
                                    </div> */}
                                        </div>
                                    </div>
                                )
                            })}



                            {/*  */}

                        </div>
                    </div>
                </div>
                <GoToTop />
            </div>
        </>
    )

}
export default DoctorMyPatient;